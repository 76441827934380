import React, { useEffect, useState } from 'react';
import { Box, Typography, Select, MenuItem, Skeleton } from '@mui/material';
import { fetchOrdersV2, updateOrderV2 } from '../api/ordersV2';
import { fetchIntegrations } from '../api/integrations';


const MobileDashboard = () => {
  const [orders, setOrders] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [integrations, setIntegrations] = React.useState([]);

  useEffect(() => {
    const initializeData = async () => {
      try {
        setLoadingData(true);
        const integrations_data = await fetchIntegrations();
        const data = await fetchOrdersV2(null, null, null, 'PO Sent,In Transit');
        setOrders(data);
        setIntegrations(integrations_data);
      } catch (error) {
        console.error('Error fetching integrations:', error);
      } finally {
        setLoadingData(false);
      }
    };

    initializeData();
  }, []);

  return (
    <Box
      sx={{
        flex: '1',
        flexDirection: 'column',
        display: 'flex',
        alignItems: 'top',
        bgcolor: 'grey.200',
        borderRadius: '10px',
          border: '1px solid #ccc',
        justifyContent: 'left',
        mx: 1,
        my: 1,
        p: 1,
        overflowY: 'auto',
        gap: 1,
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="body2" color="grey.800" fontWeight={'bold'} component={'div'} sx={{ borderBottom: '1px solid #ccc', width: '100%', p: 0.5 }}>
          Open Orders
        </Typography>
      </Box>
      {loadingData ? (
        <>
          <Skeleton variant="text" height={20} />
          <Skeleton variant="text" height={20} />
          <Skeleton variant="text" height={20} />
          <Skeleton variant="text" height={20} />
        </>
      ) : (
        orders.map((order, index) => (
          <Box
            key={index}
            sx={{
              flexDirection: 'column',
              display: 'flex',
              alignItems: 'start',
              borderBottom: '1px solid #ccc',
              p: 0.5,
              mb: 0,
              gap: 0.25,
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
              <Typography variant="body2" color="grey.900" fontWeight="bold" fontSize={"0.85rem"}>
                {order.order_name}
              </Typography>
              <Typography variant="body2" color="grey.900" fontSize={"0.85rem"}>
                Ordered: {order.order_date}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%', gap: 0.5 }}>
              <Select
                size='small'
                value={order.integration_id}
                onChange={(e) => {
                  updateOrderV2(order.id, { ...order, integration_id: e.target.value });
                  setOrders(orders.map((o) => (o.id === order.id ? { ...o, integration_id: e.target.value } : o)));
                }}
                sx={{
                  my: 0.25,
                  width: '50%',
                  '.MuiInputBase-input': {
                    bgcolor: 'white',
                    px: 1.5,
                    py: 0.5,
                    fontSize: '0.75rem',
                  },
                }}
              >
                {integrations.map((integration) => (
                  <MenuItem key={integration.id} value={integration.id}>
                    {integration.name}
                  </MenuItem>
                ))}
              </Select>
              <Select
                size='small'
                value={order.integration_id_to}
                onChange={(e) => {
                  updateOrderV2(order.id, { ...order, integration_id_to: e.target.value });
                  setOrders(orders.map((o) => (o.id === order.id ? { ...o, integration_id_to: e.target.value } : o)));
                }}
                sx={{
                  my: 0.25,
                  width: '50%',
                  '.MuiInputBase-input': {
                    bgcolor: 'white',
                    px: 1.5,
                    py: 0.5,
                    fontSize: '0.75rem',
                  },
                }}
              >
                {integrations.map((integration) => (
                  <MenuItem key={integration.id} value={integration.id}>
                    {integration.name}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Select
              size='small'
              fullWidth
              value={order.status}
              onChange={(e) => {
                updateOrderV2(order.id, { ...order, status: e.target.value })
                setOrders(orders.map((o) => (o.id === order.id ? { ...o, status: e.target.value } : o)));
              }}
              sx={{
                my: 0.25,
                '.MuiInputBase-input': {
                  bgcolor: 'white',
                  px: 1.5,
                  py: 0.5,
                  fontSize: '0.75rem',
                },
              }}
            >
              <MenuItem value="PO Sent">PO Sent</MenuItem>
              <MenuItem value="In Transit">In Transit</MenuItem>
              <MenuItem value="Delivered">Delivered</MenuItem>
            </Select>
            <Typography variant="body2" color="grey.900" fontSize={"0.75rem"} fontWeight={'bold'}>
              Order Items
            </Typography>
            {order.header_detail && order.header_detail.map((detail, detailIndex) => (
              <Box
                key={detailIndex}
                sx={{
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                  pl: 1.5,
                  mt: 0.25,
                }}
              >
                <Typography variant="body2" color="grey.800" sx={{ fontSize: '0.75rem' }}>
                  {detail.item_name}
                </Typography>
                <Typography variant="body2" color="grey.800" sx={{ fontSize: '0.75rem', fontWeight: 'bold' }}>
                  {Number(detail.order_qty).toLocaleString()} units
                </Typography>
              </Box>
            ))}
          </Box>
        ))
      )}
    </Box>
  );
};

export default MobileDashboard;