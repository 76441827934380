import React, { useState } from 'react';
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  IconButton,
  Typography,
} from '@mui/material';
import { Add, Remove } from '@mui/icons-material';
import { createWorkflow } from '../api/workflowV2';
import InterestsIcon from '@mui/icons-material/Interests';
import TableChartIcon from '@mui/icons-material/TableChart';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';

const CreateWorkflowDialog = ({ dialogOpen, onClose, setCards }) => {
  const [formData, setFormData] = useState({
    name: '',
    type: 'Email Body',
    template: [],
    sender: '',
    subject: '',
    file_type: '',
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleTemplateChange = (index, field, value) => {
    setFormData((prevState) => {
      const updatedTemplates = [...prevState.template];
      updatedTemplates[index][field] = value;
      return { ...prevState, template: updatedTemplates };
    });
  };

  const addField = () => {
    setFormData((prevState) => ({
      ...prevState,
      template: [...prevState.template, { value: '', description: '' }],
    }));
  };

  const addTable = () => {
    if (!formData.template.some(t => t.table)) {
      setFormData((prevState) => ({
        ...prevState,
        template: [...prevState.template, { table: [{ column: '', description: '' }] }],
      }));
    }
  };

  const removeTable = () => {
    setFormData((prevState) => ({
      ...prevState,
      template: prevState.template.filter(t => !t.table),
    }));
  };

  const addTableColumn = () => {
    setFormData((prevState) => {
      const updatedTemplates = prevState.template.map(template => {
        if (template.table) {
          return { ...template, table: [...template.table, { column: '', description: '' }] };
        }
        return template;
      });
      return { ...prevState, template: updatedTemplates };
    });
  };

  const removeTableColumn = (colIndex) => {
    setFormData((prevState) => {
      const updatedTemplates = prevState.template.map(template => {
        if (template.table) {
          return { ...template, table: template.table.filter((_, i) => i !== colIndex) };
        }
        return template;
      });
      return { ...prevState, template: updatedTemplates };
    });
  };

  const removeTemplate = (index) => {
    setFormData((prevState) => ({
      ...prevState,
      template: prevState.template.filter((_, i) => i !== index),
    }));
  };

  const handleSubmit = async () => {
    try {
      const data = await createWorkflow(formData);
      setCards((prevCards) => [data, ...prevCards]);
      handleClose();
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleClose = () => {
    onClose();
    setFormData({
      name: '',
      type: 'Email Body',
      template: [],
      sender: '',
      prompt: '',
      subject: '',
      file_type: '',
    });
  };

  return (
    <Dialog open={dialogOpen} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle
        sx={{ pb: 0, display: 'flex', alignItems: 'center' }}
      >
        Create Workflow
        <InterestsIcon fontSize="small" sx={{ ml: '0.25rem' }} />
      </DialogTitle>
      <DialogContent>
        {/* <TextField
          fullWidth
          margin="dense"
          select
          label="Type"
          name="type"
          value={formData.type}
          onChange={handleChange}
          size="small"
        >
          <MenuItem value="Email Body">Email Body</MenuItem>
          <MenuItem value="Attachment">Attachment</MenuItem>
        </TextField> */}
        <Box display={'flex'} gap={'0.5rem'}>
          <TextField
            fullWidth
            margin="dense"
            label="Rule Name"
            name="name"
            autoComplete='off'
            value={formData.name}
            onChange={handleChange}
            size="small"
          />
          <TextField
            fullWidth
            margin="dense"
            label="Sender"
            name="sender"
            autoComplete='off'
            value={formData.sender}
            onChange={handleChange}
            size="small"
          />
        </Box>
        <TextField
          fullWidth
          margin="dense"
          label="Extra prompt instructions..."
          name="prompt"
          autoComplete='off'
          multiline
          rows={4}
          value={formData.prompt}
          onChange={handleChange}
          size="small"
        />
        <Box display={'flex'} flexDirection={'column'} py={'0.25rem'} px={'1rem'} mt={'0.5rem'} sx={{ border: '1px solid #ccc', borderRadius: '4px' }}>
          <Typography variant="body2" sx={{ my: '0.5rem' }}><strong>Extraction Template</strong></Typography>
          <Box sx={{ maxHeight: '45vh', overflowY: 'auto', pt: '0.3rem' }}>
            {formData.template.map((template, index) => (
              template.table ? (
                null
              ) : (
                <Box key={index} sx={{ display: 'flex', gap: '0.25rem', alignItems: 'center', mb: '0.5rem' }}>
                  <TextField
                    label="Field"
                    size="small"
                    value={template.value}
                    onChange={(e) => handleTemplateChange(index, 'value', e.target.value)}
                    fullWidth
                    autoComplete='off'
                    sx={{ width: '50%' }}
                  />
                  <TextField
                    label="Description"
                    size="small"
                    value={template.description}
                    onChange={(e) => handleTemplateChange(index, 'description', e.target.value)}
                    fullWidth
                    autoComplete='off'
                  />
                  <IconButton onClick={() => removeTemplate(index)}>
                    <Remove fontSize='small' />
                  </IconButton>
                </Box>
              )
            ))}
            {formData.template.map((template, index) => (
              template.table ? (
                <Box key={index}>
                  <Typography variant="body2" sx={{ mb: '0.25rem' }} fontWeight={'bold'}>Table</Typography>
                  {template.table.map((col, colIndex) => (
                    <Box key={colIndex} sx={{ display: 'flex', gap: '0.25rem', mb: '0.5rem' }}>
                      <TextField
                        label="Column"
                        size="small"
                        value={col.column}
                        onChange={(e) => handleTemplateChange(index, 'table', template.table.map((c, i) => i === colIndex ? { ...c, column: e.target.value } : c))}
                        fullWidth
                        sx={{ width: '50%' }}
                        autoComplete='off'
                      />
                      <TextField
                        label="Description"
                        size="small"
                        value={col.description}
                        onChange={(e) => handleTemplateChange(index, 'table', template.table.map((c, i) => i === colIndex ? { ...c, description: e.target.value } : c))}
                        autoComplete='off'
                        fullWidth
                      />
                      <IconButton onClick={() => removeTableColumn(colIndex)}>
                        <Remove fontSize='small' />
                      </IconButton>
                    </Box>
                  ))}
                  <Button onClick={addTableColumn} size="small" startIcon={<ViewColumnIcon />} color="primary">Add Column</Button>
                </Box>
              ) : (
                null
              )
            ))}
            <Button onClick={addField} size="small" startIcon={<Add />} color="primary" sx={{ mr: '0.5rem' }}>Add Field</Button>
            {formData.template.some(t => t.table) ? (
              <Button onClick={removeTable} size="small" startIcon={<Remove />} color="secondary">Remove Table</Button>
            ) : (
              <Button onClick={addTable} size="small" startIcon={<TableChartIcon />} color="primary">Add Table</Button>
            )}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">Close</Button>
        <Button onClick={handleSubmit} color="primary" variant="contained" sx={{ borderRadius: '10px' }}>Submit</Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateWorkflowDialog;
