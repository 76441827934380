import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  Typography,
  Box,
  Tooltip,
  Skeleton,
  TextField,
  Button,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { fetchWorkflowRuns, deleteWorkflowRun, updateWorkflow, updateWorkflowRun } from '../api/workflowV2';
import EditIcon from '@mui/icons-material/Edit';
import { format } from 'date-fns';
import { fromZonedTime } from 'date-fns-tz';
import CircleIcon from '@mui/icons-material/Circle';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CloseIcon from '@mui/icons-material/Close';

const WorkflowDetailDialog = ({
  open,
  onClose,
  selectedWorkflow,
  setSelectedWorkflow,
  setCards,
}) => {
  const [rows, setRows] = useState([]);
  const [loadingRows, setLoadingRows] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedWorkflow, setEditedWorkflow] = useState({});
  const [hideRuns, setHideRuns] = useState(true);
  const [viewMode, setViewMode] = useState('fields');

  useEffect(() => {
    const fetchData = async (id) => {
      try {
        setLoadingRows(true);
        const data = await fetchWorkflowRuns(id);
        setRows(data);
      } catch (error) {
        console.error('Error fetching shipments:', error);
      } finally {
        setLoadingRows(false);
      }
    };

    if (selectedWorkflow) {
      fetchData(selectedWorkflow.id);
      setEditedWorkflow(selectedWorkflow);
    }
  }, [selectedWorkflow]);

  const handleDeleteRun = async (id) => {
    setRows((prevRows) => prevRows.filter((row) => row.id !== id));
    await deleteWorkflowRun(id);
  };

  const handleClose = () => {
    setHideRuns(true);
    setRows([]);
    setIsEditing(false);
    onClose();
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSave = async () => {
    setSelectedWorkflow(editedWorkflow);
    setCards((prevCards) =>
      prevCards.map((card) => (card.id === editedWorkflow.id ? editedWorkflow : card))
    );
    await updateWorkflow(editedWorkflow);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setEditedWorkflow(selectedWorkflow);
    setIsEditing(false);
  };

  const handleProcessRowUpdate = async (newRow) => {
    const updatedRow = { ...newRow };
    await updateWorkflowRun(updatedRow);
    setRows((prevRows) => prevRows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const columns = [
    {
      field: 'create_time',
      headerName: 'run_date',
      width: 160,
      renderCell: (params) => {
        const value = params.value || params.row.create_time;
        if (!value) {
          return '';
        }
        const date = new Date(value);
        const zonedDate = fromZonedTime(date, 'UTC');
        return format(zonedDate, 'MM/dd/yy hh:mm a');
      }
    },
    ...(selectedWorkflow?.template?.flatMap(entry =>
      entry.table ? entry.table.map((column) => ({
        field: column.column,
        headerName: column.column,
        flex: 1,
        editable: false,
        renderCell: (params) => {
          const value = params.value;
          if (!value) {
            return '';
          }
          if (typeof value === 'string' && value.startsWith('http')) {
            return (
              <Button
                variant="text"
                color="primary"
                size='small'
                onClick={() => window.open(value, '_blank')}
              >
                Link
              </Button>
            );
          }
          return !isNaN(value) ? Number(value).toLocaleString() : value;
        }
      })) : []
    ) || [])
  ];

  const nonTableColumns = [
    {
      field: 'create_time',
      headerName: 'run_date',
      width: 160,
      renderCell: (params) => {
        const value = params.value || params.row.create_time;
        if (!value) {
          return '';
        }
        const date = new Date(value);
        const zonedDate = fromZonedTime(date, 'UTC');
        return format(zonedDate, 'MM/dd/yy hh:mm a');
      }
    },
    ...(selectedWorkflow?.template?.filter(entry => !entry.table).map(entry => ({
      field: entry.value,
      headerName: entry.value,
      flex: 1,
      editable: false,
      renderCell: (params) => {
        const value = params.value;
        if (!value) {
          return '';
        }
        if (typeof value === 'string' && value.startsWith('http')) {
          return (
            <Button
              variant="text"
              color="primary"
              size='small'
              onClick={() => window.open(value, '_blank')}
            >
              Link
            </Button>
          );
        }
        return !isNaN(value) ? Number(value).toLocaleString() : value;
      }
    })) || [])
  ];

  const hasFields = nonTableColumns?.length > 1;
  const hasTable = columns?.length > 1;

  useEffect(() => {
    if (hasFields) {
      setViewMode('fields');
    } else if (hasTable) {
      setViewMode('table');
    }
  }, [hasFields, hasTable]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="xl"
      BackdropProps={{
        sx: {
          backgroundColor: "rgba(0, 0, 0, 0.1)",
        },
      }}
    >
      <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <Typography variant="h6" component={'div'} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {isEditing ? (
                <>
                  <TextField
                    size="small"
                    variant="outlined"
                    value={editedWorkflow.name}
                    sx={{ minWidth: 300 }}
                    onChange={(e) => setEditedWorkflow({ ...editedWorkflow, name: e.target.value })}
                  />

                  <Box sx={{ display: 'flex', gap: 1, ml: 1 }}>
                    <Button variant="contained" color="primary" sx={{ borderRadius: '10px' }} onClick={handleSave}>Save</Button>
                    <Button variant="text" onClick={handleCancel}>Cancel</Button>
                  </Box>
                </>
              ) : (
                <b>{selectedWorkflow?.name}</b>
              )}
              {!isEditing && <EditIcon fontSize='small' sx={{ cursor: 'pointer', ml: 1 }} onClick={handleEditClick} />}
            </Box>
            <CloseIcon color="error" sx={{ ml: 1, cursor: 'pointer' }} onClick={handleClose} />
          </Typography>

          {isEditing ? (
            <>
              <TextField
                fullWidth
                size="small"
                variant="outlined"
                label="Sender"
                value={editedWorkflow.sender}
                onChange={(e) => setEditedWorkflow({ ...editedWorkflow, sender: e.target.value })}
                sx={{ mt: 1 }}
              />
              <TextField
                fullWidth
                size="small"
                variant="outlined"
                label="Prompt"
                value={editedWorkflow.prompt}
                onChange={(e) => setEditedWorkflow({ ...editedWorkflow, prompt: e.target.value })}
                sx={{ mt: 1 }}
              />
            </>
          ) : (
            <>
              <Typography variant="body2"><b>Sender</b>: {selectedWorkflow?.sender}</Typography>
              {selectedWorkflow?.prompt && <Typography variant="body2"><b>Prompt</b>: {selectedWorkflow?.prompt}</Typography>}
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', gap: 1, width: '100%', mt: 0.5 }}>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => setHideRuns((prev) => !prev)}
                >
                  {hideRuns ? "Show Runs" : "Hide Runs"}
                </Button>
                <Box>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => setViewMode('fields')}
                    disabled={!hasFields}
                  >
                    Fields
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => setViewMode('table')}
                    disabled={!hasTable}
                    sx={{ ml: 1 }}
                  >
                    Table
                  </Button>
                </Box>
              </Box>
            </>
          )}
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'row', mt: 1, width: '100%' }}>
          {!hideRuns && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '15%',
                height: '65vh',
                py: 1,
                pr: 1,
                overflowY: 'auto'
              }}
            >
              {loadingRows ? (
                <Box display="flex" flexDirection="column" alignItems="center" gap={1} width="100%">
                  {[...Array(4)].map((_, index) => (
                    <Skeleton key={index} variant="rounded" width={'100%'} height={25} />
                  ))}
                </Box>
              ) : rows.length === 0 ? (
                <Typography variant="body2"><b>No runs found.</b></Typography>
              ) : (
                rows.map((row, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      border: '1px solid #ccc',
                      borderRadius: 2,
                      padding: 1,
                      marginBottom: 1,
                      backgroundColor: '#f9f9f9',
                    }}
                  >
                    <Typography variant="caption">
                      {(() => {
                        const date = new Date(row.create_time);
                        const zonedDate = fromZonedTime(date, 'UTC');
                        return format(zonedDate, 'MM/dd/yy hh:mm a');
                      })()}
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Tooltip title={row.status} placement="left">
                        <CircleIcon fontSize="small" color={row.status === 'success' ? 'success' : 'error'} sx={{ cursor: 'pointer' }} />
                      </Tooltip>
                      <DeleteOutlineIcon fontSize="small" sx={{ ml: 1, color: 'grey.800', cursor: 'pointer' }} onClick={() => handleDeleteRun(row.id)} />
                    </Box>
                  </Box>
                ))
              )}
            </Box>
          )}
          <Box sx={{ width: hideRuns ? '100%' : '85%', py: 1 }}>
            <Box sx={{ height: '65vh', maxHeight: '65vh', width: '100%' }}>
              {viewMode === 'table' ? (
                <DataGrid
                  rows={rows.length > 0 ? rows.flatMap((row, index) =>
                    row.content.table?.map((entry, subIndex) => ({
                      id: entry?.table_id ? entry.table_id : `${index}-${subIndex}`,
                      run_id: row.id,
                      table_flag: true,
                      create_time: row.create_time,
                      ...entry
                    })) || []
                  ) : []}
                  density='compact'
                  columns={columns}
                  hideFooter
                  processRowUpdate={handleProcessRowUpdate}
                />
              ) : (
                <DataGrid
                  rows={rows}
                  density='compact'
                  columns={nonTableColumns}
                  hideFooter
                  processRowUpdate={handleProcessRowUpdate}
                />
              )}
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default WorkflowDetailDialog;