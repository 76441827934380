import React from 'react';
import {
  Box,
  Button,
  Divider,
  Typography,
  Tooltip,
  TextField,
  InputAdornment,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from '@mui/material';
import trinity_logo from '../assets/logo.png';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import StyledIconButton from '../components/StyledIconButton';
import AddIcon from '@mui/icons-material/Add';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import CreatePartnerDialog from '../components/CreatePartnerDialog';
import ViewListIcon from '@mui/icons-material/ViewList';
import PublicIcon from '@mui/icons-material/Public';
import SyncIcon from '@mui/icons-material/Sync';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import PushPinIcon from '@mui/icons-material/PushPin';
import SearchIcon from '@mui/icons-material/Search';
import gmail_logo from '../assets/icons8-gmail-48.png';
import outlook_logo from '../assets/icons8-outlook-48.png';
import quickbooks_logo from '../assets/icons8-quickbooks-32.png';
import { useGoogleAuth } from '../components/GoogleAuthContext';
import { deleteIntegration, createQuickbooksToken, createShopifyToken, fetchShopifyAuthUrl, createOutlookToken } from '../api/integrations';
import { refreshDocuments } from '../api/documents';
import { fetchOrdersV2, fetchDocumentsV2, fetchOrderDetailsV2 } from '../api/ordersV2';
import { fetchSources, deleteSource } from '../api/notifications';
import DocumentsV2 from '../components/datagrid/DocumentsV2';
import OrdersV2 from '../components/datagrid/OrdersV2';
import OrderDetailsV2 from '../components/datagrid/OrderDetailsV2';
import SnackbarAlert from '../components/SnackbarAlert';
import DocumentsPopover from '../components/DocumentsPopover';
import SettingsDialog from '../components/SettingsDialog';
import AgentComponent from '../components/AgentComponent';
import WorkflowsComponent from '../components/WorkflowsComponent';
import { format } from 'date-fns';
import { fromZonedTime } from 'date-fns-tz';
import MapComponentV2 from '../components/MapComponentV2';
import IntegrationDialogViewV2 from '../components/IntegrationDialogViewV2';


const EmailClient = ({
  user,
  setUser,
  integrations,
  setIntegrations,
  dataSources,
  setDataSources,
  integrationTypes,
  signOut
}) => {
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarAlert, setSnackbarAlert] = React.useState("");
  const [selectedTable, setSelectedTable] = React.useState("Map");
  const [selectedSubView, setSelectedSubView] = React.useState("Header");
  const [orderRows, setOrderRows] = React.useState([]);
  const [documentRows, setDocumentRows] = React.useState([]);
  const [inventoryRows, setInventoryRows] = React.useState([]);
  const [filteredIntegrationRows, setFilteredIntegrationRows] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [partnerOpen, setPartnerOpen] = React.useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const [deletingId, setDeletingId] = React.useState("");
  const [settingsDialogOpen, setSettingsDialogOpen] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [docsAnchorEl, setDocsAnchorEl] = React.useState(null);
  const [pinnedDocuments, setPinnedDocuments] = React.useState([]);
  const [selectedIntegration, setSelectedIntegration] = React.useState("");
  const { gmailLogin } = useGoogleAuth();

  React.useEffect(() => {
    const checkUrlParams = async () => {
      try {
        const urlParams = new URLSearchParams(window.location.search);
        const shop = urlParams.get('shop');
        const code = urlParams.get('code');
        const realm_id = urlParams.get('realmId');
        if (realm_id && code) {
          await createQuickbooksToken(code, realm_id);
          window.history.pushState({}, '', window.location.pathname);
          setSnackbarOpen(true);
          setSnackbarMessage('QuickBooks linked successfully');
          setSnackbarAlert('success');
          const sources_data = await fetchSources();
          setDataSources(sources_data);
        }
        else if (shop && code) {
          await createShopifyToken(shop, code);
          window.history.pushState({}, '', window.location.pathname);
          setSnackbarOpen(true);
          setSnackbarMessage('Shopify linked successfully');
          setSnackbarAlert('success');
          const sources_data = await fetchSources();
          setDataSources(sources_data);
        }
        else if (shop) {
          const redirect_uri = await fetchShopifyAuthUrl(shop);
          console.log(redirect_uri);
          window.location.href = redirect_uri.detail.authorization_url;
        }
        else if (code) {
          await createOutlookToken(code, 'https://' + process.env.REACT_APP_REDIRECT_URI);
          window.history.pushState({}, '', window.location.pathname);
          setSnackbarOpen(true);
          setSnackbarMessage('Outlook linked successfully');
          setSnackbarAlert('success');
          const sources_data = await fetchSources();
          setDataSources(sources_data);
        }
      } catch (error) {
        console.error('Error exchanging token:', error);
        setSnackbarOpen(true);
        setSnackbarMessage('Failed to exchange token');
        setSnackbarAlert('error');
      }
    }

    checkUrlParams();
  }, [setDataSources]);

  React.useEffect(() => {
    setSearchTerm("");
    setFilteredIntegrationRows([]);
    if (selectedTable === "Orders") {
      setLoading(true);
      const fetchOrdersData = async () => {
        try {
          const orders_data = await fetchOrdersV2();
          setOrderRows(orders_data);
        } catch (error) {
          console.error('Error fetching orders:', error);
        } finally {
          setLoading(false);
        }
      };

      const fetchInventoryData = async () => {
        try {
          const details_data = await fetchOrderDetailsV2();
          setInventoryRows(details_data);
        } catch (error) {
          console.error('Error fetching inventory:', error);
        } finally {
          setLoading(false);
        }
      };

      if (selectedSubView === "Header") {
        fetchOrdersData();
      } else if (selectedSubView === "Itemized") {
        fetchInventoryData();
      }
    } else if (selectedTable === "Documents") {
      setLoading(true);
      const fetchDocumentData = async () => {
        try {
          const documents_data = await fetchDocumentsV2(null, false);
          setDocumentRows(documents_data);
        } catch (error) {
          console.error('Error fetching documents:', error);
        } finally {
          setLoading(false);
        }
      };
      fetchDocumentData();
    }
  }, [selectedTable, selectedSubView]);


  const handleLogout = async () => {
    localStorage.removeItem('apiToken');
    await signOut();
  };

  const handleClose = () => {
    setDialogOpen(false);
  };


  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSearchData = async (event) => {
    setLoading(true);
    if (event.key === 'Enter') {
      setSearchTerm(event.target.value);
      if (selectedTable === "Orders") {
        if (selectedSubView === "Header") {
          const filteredOrders = await fetchOrdersV2(event.target.value);
          setOrderRows(filteredOrders);
          setLoading(false);
        } else if (selectedSubView === "Itemized") {
          const filteredInventory = await fetchOrderDetailsV2(event.target.value);
          setInventoryRows(filteredInventory);
          setLoading(false);
        }
      } else if (selectedTable === "Documents") {
        const filteredOrders = await fetchDocumentsV2(event.target.value, false);
        setDocumentRows(filteredOrders);
        setLoading(false);
      } else if (selectedTable === "Inventory") {
        const filteredInventory = await fetchOrderDetailsV2(event.target.value);
        setInventoryRows(filteredInventory);
        setLoading(false);
      } else if (selectedTable === "Map" && event.target.value) {
        const filteredData = await fetchOrderDetailsV2(event.target.value);
        const uniqueIntegrationIds = [...new Set(filteredData.map(row => row.integration_id))];
        const uniqueIntegrationIdsTo = [...new Set(filteredData.map(row => row.integration_id_to))];
        const allUniqueIntegrationIds = new Set([...uniqueIntegrationIds, ...uniqueIntegrationIdsTo]);
        setFilteredIntegrationRows([...allUniqueIntegrationIds]);
        setLoading(false);
      } else {
        setFilteredIntegrationRows([]);
        setLoading(false);
      }
    }
  };

  const handleDeletePartner = async () => {
    await deleteIntegration(deletingId);
    setIntegrations((prevIntegrations) => prevIntegrations.filter((integration) => integration.id !== deletingId));
    setDeletingId("");
    setDeleteDialogOpen(false);
  };

  const handleRefreshDocuments = async (integration_id) => {
    try {
      await refreshDocuments(integration_id);
      setSnackbarOpen(true);
      setSnackbarMessage("Refresh triggered. Check back in a minute.");
      setSnackbarAlert("success");
    } catch (error) {
      console.error('Error:', error);
      setSnackbarOpen(true);
      setSnackbarMessage(error.response.data.detail);
      setSnackbarAlert("error");
    }
  };


  const handlePinnedDocuments = async (event, integration_id) => {
    const pinned_docs = await fetchDocumentsV2(null, null, "True", integration_id);
    setPinnedDocuments(pinned_docs);
    setDocsAnchorEl(event.target)
  };

  const handleQuickbooksLogin = () => {
    const queryParams = new URLSearchParams({
      client_id: process.env.REACT_APP_QB_CLIENT,
      redirect_uri: 'https://' + process.env.REACT_APP_REDIRECT_URI,
      response_type: 'code',
      scope: 'com.intuit.quickbooks.accounting',
      state: 'random_state_string'
    });
    window.location.href = `https://appcenter.intuit.com/connect/oauth2?${queryParams.toString()}`;
  };

  const handleOutlookLogin = () => {
    const scopes = encodeURIComponent('User.Read Mail.Read offline_access');
    const redirect_uri = 'https://' + process.env.REACT_APP_REDIRECT_URI;
    const client_id = process.env.REACT_APP_OUTLOOK_CLIENT;
    const authUrl = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize` +
      `?client_id=${client_id}` +
      `&response_type=code` +
      `&redirect_uri=${redirect_uri}` +
      `&scope=${scopes}`;
    window.location.href = authUrl;
  };

  return (
    <Box sx={{ height: '100vh', bgcolor: '#ebebeb', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', p: '0.25rem' }}>
      <Box
        sx={{
          width: '100%',
          flex: '0 0 auto',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            bgcolor: '#2b2b2b',
            py: '0.3rem',
            px: '1rem',
            borderRadius: '15px',
          }}
        >
          <img src={trinity_logo} alt="Trinity Logo" style={{ width: '95px' }} />
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <StyledIconButton icon={<SettingsIcon />} onClick={() => setSettingsDialogOpen(true)} tooltip={'Settings'} />
            <StyledIconButton icon={<LogoutIcon />} onClick={handleLogout} tooltip={'Logout'} />
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="left"
          justifyContent="center"
          sx={{
            width: '100%',
          }}
        >
          <Box
            sx={{
              width: '20%',
              height: '93vh',
              p: '0.5rem',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            {/* Non-scrolling section */}
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                pb: '0.25rem',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'left',
                  flexDirection: 'column',
                  width: '100%',
                  border: '1px solid #ccc',
                  my: '0.1rem',
                  borderRadius: '10px',
                  boxShadow: 'none',
                  p: 1,
                  bgcolor: 'white',
                }}
              >
                <Typography
                  variant="body2"
                  display={'flex'}
                  onClick={() => setSelectedTable('Workflows')}
                >
                  <b>Data Sources</b>
                </Typography>
                {dataSources.map((dataSource) => (
                  <Typography
                    key={dataSource.id}
                    variant="body2"
                    display={'flex'}
                    alignItems={'center'}
                  >
                    <Tooltip
                      title={`Last updated: ${format(
                        fromZonedTime(new Date(dataSource.last_updated), 'UTC'),
                        'MM/dd/yyyy hh:mm:ss a'
                      )}`}
                      placement="right"
                      arrow
                    >
                      <CheckCircleOutlineIcon
                        fontSize="small"
                        sx={{ color: 'green', mr: '0.25rem' }}
                      />
                    </Tooltip>
                    {dataSource.type === 'Gmail' ? dataSource.email : dataSource.type}
                    <DeleteOutlineOutlinedIcon
                      fontSize="small"
                      color="error"
                      sx={{ ml: '0.25rem', cursor: 'pointer' }}
                      onClick={async () => {
                        await deleteSource(dataSource.id);
                        setDataSources(dataSources.filter((source) => source.id !== dataSource.id));
                      }}
                    />
                  </Typography>
                ))}
                <Button
                  variant="outlined"
                  startIcon={<img src={gmail_logo} alt="Gmail Logo" style={{ width: '25px', height: '25px' }} />}
                  onClick={gmailLogin}
                  sx={{
                    width: '100%',
                    borderRadius: '10px',
                    border: '1px solid #ccc',
                    textTransform: 'none',
                    my: '0.1rem',
                  }}
                >
                  Link Gmail
                </Button>
                <Button
                  variant="outlined"
                  startIcon={<img src={outlook_logo} alt="Outlook Logo" style={{ width: '25px', height: '25px' }} />}
                  onClick={handleOutlookLogin}
                  sx={{
                    width: '100%',
                    borderRadius: '10px',
                    border: '1px solid #ccc',
                    textTransform: 'none',
                    my: '0.1rem',
                  }}
                >
                  Link Outlook
                </Button>
                <Button
                  variant="outlined"
                  startIcon={<img src={quickbooks_logo} alt="QuickBooks Logo" style={{ width: '25px', height: '25px' }} />}
                  onClick={handleQuickbooksLogin}
                  sx={{
                    width: '100%',
                    borderRadius: '10px',
                    border: '1px solid #ccc',
                    textTransform: 'none',
                    my: '0.1rem',
                  }}
                >
                  Link QuickBooks
                </Button>
              </Box>
              <Divider color="#ccc" sx={{ my: '0.1rem', width: '95%' }} />
              <Button
                variant="contained"
                onClick={() => setDialogOpen(true)}
                startIcon={<AddIcon />}
                sx={{
                  width: '100%',
                  borderRadius: '10px',
                  textTransform: 'none',
                  mt: '0.1rem',
                }}
              >
                Add Partner
              </Button>
            </Box>

            <Box
              sx={{
                flex: '1 1 auto',
                pr: 0.2,
                width: '100%',
                overflowY: 'auto',
                '&::-webkit-scrollbar': {
                  bgcolor: '#ebebeb',
                  width: '3px',
                },
                '&::-webkit-scrollbar-thumb': {
                  bgcolor: 'darkgrey',
                  borderRadius: '15px',
                },
              }}
            >
              {[...integrations.filter((integration) => integration.integration_type.category === 'HQ'),
              ...integrations.filter((integration) => integration.integration_type.category !== 'HQ')].map((integration) => (
                <Box
                  key={integration.id}
                  sx={{
                    width: '100%',
                    p: '1rem',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    bgcolor: 'white',
                    borderRadius: '10px',
                    gap: '0.25rem',
                    border:
                      integration.integration_type.category === 'HQ'
                        ? '1px solid black'
                        : '1px solid #ccc',
                    mb: '0.5rem',
                    boxShadow: 'none',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      flex: '1 1 auto',
                      cursor: 'pointer',
                      overflow: 'hidden',
                    }}
                    onClick={() => {
                      setSelectedIntegration(integration);
                      setPartnerOpen(true);
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <b>{integration.name}</b>
                    </Typography>
                    <Typography variant="body2">
                      {integration.integration_type.type}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {integration.contact_email}
                    </Typography>
                  </Box>
                  <Divider orientation="vertical" flexItem />
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '1px' }}>
                    <DocumentsPopover
                      anchorEl={docsAnchorEl}
                      setAnchorEl={setDocsAnchorEl}
                      selectedDocuments={pinnedDocuments}
                    />
                    <PushPinIcon
                      color="secondary"
                      fontSize="small"
                      sx={{ cursor: 'pointer' }}
                      onClick={(event) => handlePinnedDocuments(event, integration.id)}
                    />
                    {integration.integration_type.category !== 'HQ' && (
                      <>
                        <Tooltip title="Sync Now" placement="right">
                          <SyncIcon
                            color="info"
                            fontSize="small"
                            onClick={() => handleRefreshDocuments(integration.id)}
                            sx={{ cursor: 'pointer' }}
                          />
                        </Tooltip>
                        <Tooltip title="Delete" placement="right">
                          <DeleteOutlineOutlinedIcon
                            color="error"
                            sx={{ cursor: 'pointer' }}
                            fontSize="small"
                            onClick={() => {
                              setDeletingId(integration.id);
                              setDeleteDialogOpen(true);
                            }}
                          />
                        </Tooltip>
                      </>
                    )}
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
          <Divider orientation="vertical" flexItem sx={{ mt: '1rem' }} />
          <Box
            sx={{
              width: '80%',
              height: '93vh',
              p: '0.5rem',
            }}
          >
            <Box
              sx={{
                width: '100%',
                gap: '0.5rem',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Box
                sx={{
                  gap: '1rem',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '1rem',
                    alignItems: 'center',
                  }}
                >
                  <Button
                    variant={selectedTable === 'Map' ? 'contained' : "text"}
                    onClick={() => setSelectedTable('Map')}
                    startIcon={<PublicIcon />}
                    size='small'
                    sx={{
                      borderRadius: '10px',
                      textTransform: 'none',
                      minWidth: '125px',
                    }}
                  >
                    Map
                  </Button>
                  <Button
                    variant={selectedTable === 'Documents' ? 'contained' : "text"}
                    onClick={() => setSelectedTable('Documents')}
                    startIcon={<DocumentScannerIcon />}
                    size='small'
                    sx={{
                      borderRadius: '10px',
                      textTransform: 'none',
                      minWidth: '125px',
                    }}
                  >
                    Documents
                  </Button>
                  <Button
                    variant={selectedTable === 'Orders' ? 'contained' : "text"}
                    startIcon={<ViewListIcon />}
                    onClick={() => setSelectedTable('Orders')}
                    size='small'
                    sx={{
                      borderRadius: '10px',
                      textTransform: 'none',
                      minWidth: '125px',
                    }}
                  >
                    Orders
                  </Button>
                  {/* <Button
                    variant={selectedTable === 'Leads' ? 'contained' : "text"}
                    startIcon={<PeopleIcon />}
                    onClick={() => setSelectedTable('Leads')}
                    sx={{
                      borderRadius: '10px',
                      textTransform: 'none',
                      minWidth: '125px',
                    }}
                  >
                    Leads (beta)
                  </Button> */}
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    flex: 1,
                  }}
                >
                  {['Orders', 'Documents', 'Inventory', 'Map'].includes(selectedTable) && (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        width: '100%',
                      }}
                    >
                      <TextField
                        variant="outlined"
                        size="small"
                        autoComplete='off'
                        value={searchTerm}
                        placeholder={"Search"}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        onKeyPress={(e) => {
                          if (e.key === 'Enter') {
                            handleSearchData(e);
                          }
                        }}
                        fullWidth
                        sx={{
                          width: '50%',
                          '& .MuiOutlinedInput-root': {
                            borderRadius: '10px',
                            bgcolor: 'white',
                          },
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                  )}
                </Box>
              </Box>
              <Box>
                <>
                  {selectedTable === 'Map' ? (
                    <MapComponentV2
                      hasQbLinked={dataSources.some((source) => source.type === 'Quickbooks')}
                      integrations={integrations}
                      setIntegrations={setIntegrations}
                      setSnackbarAlert={setSnackbarAlert}
                      setSnackbarOpen={setSnackbarOpen}
                      setSnackbarMessage={setSnackbarMessage}
                      filteredIntegrations={filteredIntegrationRows}
                    />
                  ) : (selectedTable === 'Orders' && selectedSubView === 'Header') ? (
                    <OrdersV2
                      rows={orderRows}
                      setRows={setOrderRows}
                      integrations={integrations}
                      selectedSubView={selectedSubView}
                      setSelectedSubView={setSelectedSubView}
                      setSnackbarAlert={setSnackbarAlert}
                      setSnackbarOpen={setSnackbarOpen}
                      setSnackbarMessage={setSnackbarMessage}
                      hasQbLinked={dataSources.some((source) => source.type === 'Quickbooks')}
                      loading={loading}
                    />
                  ) : (selectedTable === 'Orders' && selectedSubView === 'Itemized') ? (
                    <OrderDetailsV2
                      rows={inventoryRows}
                      loading={loading}
                      selectedSubView={selectedSubView}
                      setSelectedSubView={setSelectedSubView}
                    />
                  ) : selectedTable === 'Documents' ? (
                    <DocumentsV2
                      rows={documentRows}
                      setRows={setDocumentRows}
                      integrations={integrations}
                      setSnackbarAlert={setSnackbarAlert}
                      setSnackbarOpen={setSnackbarOpen}
                      setSnackbarMessage={setSnackbarMessage}
                      loading={loading}
                    />
                  ) : selectedTable === 'Leads' ? (
                    <Box sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      height: '83vh',
                      maxHeight: '83vh',
                    }}>
                      <AgentComponent />
                    </Box>
                  ) : selectedTable === 'Workflows' ? (
                    <Box sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      height: '83vh',
                      maxHeight: '83vh',
                    }}>
                      <WorkflowsComponent />
                    </Box>
                  ) : null}
                </>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <CreatePartnerDialog
        dialogOpen={dialogOpen}
        handleClose={handleClose}
        integrationTypes={integrationTypes.filter(integration => integration.category !== 'HQ')}
        setIntegrations={setIntegrations}
        setSnackbarOpen={setSnackbarOpen}
        setSnackbarMessage={setSnackbarMessage}
        setSnackbarAlert={setSnackbarAlert}
        hasGmailLinked={dataSources.some((source) => source.type === 'Gmail')}
      />
      {
        partnerOpen && (
          <IntegrationDialogViewV2
            open={partnerOpen}
            onClose={() => setPartnerOpen(false)}
            hasQbLinked={dataSources.some((source) => source.type === 'Quickbooks')}
            integration={selectedIntegration}
            setIntegration={setSelectedIntegration}
            integrations={integrations}
            setIntegrations={setIntegrations}
            setSnackbarOpen={setSnackbarOpen}
            setSnackbarMessage={setSnackbarMessage}
            setSnackbarAlert={setSnackbarAlert}
          />
        )
      }
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography variant="body2" sx={{ mb: 2 }}>
            Are you sure you want to delete this partner?
          </Typography>
          <Typography variant="body2">
            <b>It will delete all orders and documents associated with this account and cannot be undone.</b>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeletePartner} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <SettingsDialog
        user={user}
        setUser={setUser}
        open={settingsDialogOpen}
        onClose={() => setSettingsDialogOpen(false)}
        setSnackbarOpen={setSnackbarOpen}
        setSnackbarMessage={setSnackbarMessage}
        setSnackbarAlert={setSnackbarAlert}
      />
      <SnackbarAlert
        snackbarOpen={snackbarOpen}
        handleSnackbarClose={handleSnackbarClose}
        message={snackbarMessage}
        severity={snackbarAlert}
      />
    </Box>
  );
};

export default EmailClient;
