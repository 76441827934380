import React, { useState, useEffect } from 'react';
import { Box, Tooltip, Select, MenuItem, FormControl, CircularProgress } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { updateIntegration } from '../api/integrations';

const QuickBooksPartnerSelect = ({ integration, setIntegrations, handleFetchQuickbooksData, qbType }) => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState('');

  useEffect(() => {
    setSelectedCustomer(integration.native_finance_id);
  }, [integration.native_finance_id]);

  const handleOpen = async () => {
    setLoading(true);
    const data = await handleFetchQuickbooksData();
    setOptions(data);
    setLoading(false);
  };

  const handleChange = async (event) => {
    setSelectedCustomer(event.target.value);
    const updatedIntegration = { ...integration, native_finance_id: event.target.value };
    await updateIntegration(integration.id, updatedIntegration);
    setIntegrations((prevIntegrations) =>
      prevIntegrations.map((int) =>
        int.id === integration.id ? updatedIntegration : int
      )
    );
  };

  return (
    <Tooltip title={qbType === 'Customer' ? "Link to Quickbooks Customer to create invoices from sales orders." : "Link to Quickbooks Vendor to sync invoices."} placement="right">
      <FormControl
        variant="standard"
        size="small"
        sx={{
          minWidth: 300,
        }}
      >
        <Select
          labelId="quickbooks-customer-select-label"
          value={selectedCustomer}
          onChange={handleChange}
          displayEmpty
          onOpen={handleOpen}
          sx={{
            '& .MuiSelect-select': {
              p: 0.55
            }
          }}
          renderValue={(value) => {
            if (value || integration.native_finance_id) {
              return (
                <Box display="flex" alignItems="center">
                  Linked to QuickBooks <CheckIcon fontSize="small" color="success" sx={{ marginLeft: 1 }} />
                </Box>
              );
            }
            return qbType === 'Customer' ? 'Connect to QuickBooks Customer' : 'Connect to QuickBooks Vendor';
          }}
        >
          {loading ? (
            <MenuItem disabled>
              <CircularProgress size={12} />
            </MenuItem>
          ) : (
            options.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))
          )}
        </Select>
      </FormControl>
    </Tooltip>
  );
};

export default QuickBooksPartnerSelect;
