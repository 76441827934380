import { axiosInstance, checkToken } from './utils.js'



const fetchQuery = async () => {
  const url = `/v2/query/`;
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).get(url);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};


const createQuery = async (query) => {
  const url = `/v2/query/`;
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).post(url, query);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

const deleteQuery = async (id) => {
  const url = `/v2/query/${id}`;
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).delete(url);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};


export {
  fetchQuery,
  createQuery,
  deleteQuery
};
