// integrations.js

import { axiosInstance, checkToken } from './utils.js'


const fetchIntegrations = async () => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).get('/integrations/');
    return response.data;
  } catch (error) {
    console.error('Error fetching integrations:', error);
    throw error;
  }
};


const createIntegration = async (integrationData) => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).post('/integration/', integrationData);
    return response.data;
  } catch (error) {
    console.error('Error fetching integrations:', error);
    throw error;
  }
};

const updateIntegration = async (integrationId, integrationData) => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).patch(`/integration/${integrationId}`, integrationData);
    return response.data;
  } catch (error) {
    console.error('Error fetching integrations:', error);
    throw error;
  }
};

const deleteIntegration = async (integration_id) => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).delete(`/integration/${integration_id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching integrations:', error);
    throw error;
  }
};


const fetchIntegrationTypes = async () => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).get('/integration_types/');
    return response.data;
  } catch (error) {
    console.error('Error fetching integrations:', error);
    throw error;
  }
};

const fetchIntegrationRoutes = async () => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).get('/integration_routes/');
    return response.data;
  } catch (error) {
    console.error('Error fetching integrations:', error);
    throw error;
  }
};


const createExchangeToken = async (authorizationCode, authorizationType, redirectUri) => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).post('/exchange_token/',
      {
        authorizationCode: authorizationCode,
        authorizationType: authorizationType,
        redirectUri: redirectUri
      });
    return response.data;
  } catch (error) {
    console.error('Error fetching integrations:', error);
    throw error;
  }
};

const createShopifyToken = async (shop, code) => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).post('/shopify_auth_token/', { shop: shop, code: code });
    return response.data;
  } catch (error) {
    console.error('Error fetching integrations:', error);
    throw error;
  }
};

const fetchShopifyAuthUrl = async (shop) => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).get(`/shopify_auth_url/${shop}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching integrations:', error);
    throw error;
  }
};

const createQuickbooksToken = async (code, realm_id) => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).post(
      '/quickbooks_auth_token/',
      { code: code, realm_id: realm_id }
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching integrations:', error);
    throw error;
  }
};


const createOutlookToken = async (code, redirect_uri) => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).post(
      '/outlook_auth_token/',
      { code_data: code, redirect_uri: redirect_uri }
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching integrations:', error);
    throw error;
  }
};


const createWebflowToken = async (code) => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).post(
      '/webflow_auth_token/',
      { code: code }
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching integrations:', error);
    throw error;
  }
};

const fetchShopifyLocations = async () => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).get('/shopify_locations/');
    return response.data;
  } catch (error) {
    console.error('Error fetching integrations:', error);
    throw error;
  }
};

const fetchQuickbooksCustomers = async () => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).get('/quickbooks_customers/');
    return response.data;
  } catch (error) {
    console.error('Error fetching integrations:', error);
    throw error;
  }
};

const createQuickbooksInvoice = async (order_header_id) => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).get(`/quickbooks_invoice/${order_header_id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching integrations:', error);
    throw error;
  }
};

const createQuickbooksBill = async (order_header_id) => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).get(`/quickbooks_bill/${order_header_id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching integrations:', error);
    throw error;
  }
};

const fetchQuickbooksVendors = async () => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).get('/quickbooks_vendors/');
    return response.data;
  } catch (error) {
    console.error('Error fetching integrations:', error);
    throw error;
  }
};

const syncShopifyLocations = async (native_id, hq_integration_id, integration_id) => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).post('/shopify_locations/',
      {
        native_id: native_id,
        hq_integration_id: hq_integration_id,
        integration_id: integration_id
      });
    return response.data;
  } catch (error) {
    console.error('Error fetching integrations:', error);
    throw error;
  }
};



const testAirtableEndpoint = async () => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).get('/airtable_test_endpoint/');
    return response.data;
  } catch (error) {
    console.error('Error fetching integrations:', error);
    throw error;
  }
};

const testTikTokEndpoint = async () => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).get('/tiktok_test_endpoint/');
    return response.data;
  } catch (error) {
    console.error('Error fetching integrations:', error);
    throw error;
  }
};

const testWooCommerceEndpoint = async () => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).get('/woocommerce_test_endpoint/');
    return response.data;
  } catch (error) {
    console.error('Error fetching integrations:', error);
    throw error;
  }
};

const testQuickbooksEndpoint = async () => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).get('/quickbooks_test_endpoint/');
    return response.data;
  } catch (error) {
    console.error('Error fetching integrations:', error);
    throw error;
  }
};

const testGmailEndpoint = async () => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).get('/gmail_test_endpoint/');
    return response.data;
  } catch (error) {
    console.error('Error fetching integrations:', error);
    throw error;
  }
};

const testsSheetsEndpoint = async () => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).get('/sheets_test_endpoint/');
    return response.data;
  } catch (error) {
    console.error('Error fetching integrations:', error);
    throw error;
  }
};

const testsSalesEndpoint = async () => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).get('/sales_test/');
    return response.data;
  } catch (error) {
    console.error('Error fetching integrations:', error);
    throw error;
  }
};

export {
  fetchIntegrations,
  updateIntegration,
  deleteIntegration,
  fetchIntegrationTypes,
  createIntegration,
  fetchIntegrationRoutes,
  createExchangeToken,
  createShopifyToken,
  fetchShopifyAuthUrl,
  createOutlookToken,
  createQuickbooksToken,
  createWebflowToken,
  fetchShopifyLocations,
  fetchQuickbooksCustomers,
  fetchQuickbooksVendors,
  createQuickbooksInvoice,
  createQuickbooksBill,
  syncShopifyLocations,
  testAirtableEndpoint,
  testTikTokEndpoint,
  testWooCommerceEndpoint,
  testQuickbooksEndpoint,
  testGmailEndpoint,
  testsSheetsEndpoint,
  testsSalesEndpoint
};
