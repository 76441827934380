import React, { useEffect } from 'react';
import { Dialog, Typography, Box, Button, IconButton, TextField, InputAdornment, CircularProgress } from '@mui/material';
import ToggleDetailsViewV2 from '../components/ToggleDetailsViewV2';
import PlaceIcon from '@mui/icons-material/Place';
import EmailIcon from '@mui/icons-material/Email';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import PushPinIcon from '@mui/icons-material/PushPin';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { updateIntegration, fetchQuickbooksCustomers, fetchQuickbooksVendors } from '../api/integrations';
import { axiosInstance, checkToken } from '../api/utils';
import { fetchDocumentsV2, fetchOrdersV2, fetchOrderDetailsV2 } from '../api/ordersV2';
import { fetchDocumentContent } from '../api/documents.js';
import { fetchInventoryV2 } from '../api/inventoryV2';
import DocumentsV2 from '../components/datagrid/DocumentsV2';
import OrderDetailsV2 from '../components/datagrid/OrderDetailsV2';
import InventoryV2 from '../components/datagrid/InventoryV2';
import OrdersV2 from '../components/datagrid/OrdersV2';
import QuickBooksPartnerSelect from './QuickBooksPartnerSelect';


const IntegrationDialogViewV2 = ({
  open,
  onClose,
  hasQbLinked,
  integration,
  setIntegration,
  integrations,
  setIntegrations,
  setSnackbarOpen,
  setSnackbarMessage,
  setSnackbarAlert,
}) => {
  const [docmentRows, setDocumentRows] = React.useState([]);
  const [orderRows, setOrderRows] = React.useState([]);
  const [detailRows, setDetailsRows] = React.useState([]);
  const [inventoryRows, setInventoryRows] = React.useState([]);
  const [pinnedDocs, setPinnedDocuments] = React.useState([]);
  const [partnerInitialized, setPartnerInitialized] = React.useState(false);
  const [isEditing, setIsEditing] = React.useState(false);
  const [loadingData, setLoadingData] = React.useState(false);
  const [integrationEditor, setIntegrationEditor] = React.useState({});
  const [locationSearch, setLocationSearch] = React.useState('');
  const [selectedSubView, setSelectedSubView] = React.useState("Header");
  const [searchTerm, setSearchTerm] = React.useState('');
  const [currentView, setCurrentView] = React.useState('documents');
  const [searchingLocation, setSearchingLocation] = React.useState(false);


  useEffect(() => {
    const fetchInitialData = async () => {
      setSearchTerm('');
      setIsEditing(false);

      try {
        setLoadingData(true);
        const [documentData, pinnedData] = await Promise.all([
          fetchDocumentsV2(null, null, "False", integration.id),
          fetchDocumentsV2(null, null, "True", integration.id),
        ]);
        setDocumentRows(documentData);
        setPinnedDocuments(pinnedData);
      } catch (error) {
        console.error('Error fetching initial data:', error);
      } finally {
        setLoadingData(false);
        setPartnerInitialized(true);
      }
    };

    if (!partnerInitialized) {
      fetchInitialData();
    }
  }, [integration.id, partnerInitialized]);

  useEffect(() => {
    const fetchData = async () => {
      setIsEditing(false);
      setLoadingData(true);

      try {
        if (currentView === 'orders' && selectedSubView === "Header") {
          const data = await fetchOrdersV2(null, integration.id);
          setOrderRows(data);
        } else if (currentView === 'orders' && selectedSubView === "Itemized") {
          const data = await fetchOrderDetailsV2(null, integration.id);
          setDetailsRows(data);
        } else if (currentView === 'incoming' && selectedSubView === "Header") {
          const data = await fetchOrdersV2(null, null, integration.id);
          setOrderRows(data);
        } else if (currentView === 'incoming' && selectedSubView === "Itemized") {
          const data = await fetchOrderDetailsV2(null, null, integration.id);
          setDetailsRows(data);
        } else if (currentView === 'material' && integration.integration_type.category === "Manufacturer") {
          const data = await fetchInventoryV2(integration.id, currentView, true);
          setInventoryRows(data);
        } else if (currentView === 'product' && integration.integration_type.category === "3PL") {
          const data = await fetchInventoryV2(integration.id, currentView, true);
          setInventoryRows(data);
        } else if (['product', 'material'].includes(currentView)) {
          const data = await fetchInventoryV2(integration.id, currentView);
          setInventoryRows(data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoadingData(false);
      }
    };

    fetchData();
  }, [integration.id, currentView, selectedSubView, integration.integration_type?.category]);


  const handleClose = () => {
    setPartnerInitialized(false);
    setCurrentView('documents');
    setDocumentRows([]);
    setOrderRows([]);
    setSearchTerm('');
    onClose();
  };

  const handleSave = async () => {
    try {
      const typSafeIntegrationEditor = {
        ...integrationEditor,
        minimum_order_qty: integrationEditor.minimum_order_qty === '' ? null : integrationEditor.minimum_order_qty,
        default_lead_time: integrationEditor.default_lead_time === '' ? null : integrationEditor.default_lead_time,
      };

      const data = await updateIntegration(integration.id, typSafeIntegrationEditor);
      setIntegrations((prevIntegrations) =>
        prevIntegrations.map((int) =>
          int.id === data.id ? data : int
        )
      );
      setIntegration(data);
    } catch (error) {
      console.error('Error saving partner:', error);
    } finally {
      setIsEditing(false);
    }
  };

  const handleStartEditing = () => {
    setIntegrationEditor(integration);
    setIsEditing(true);
  };

  const handleLocationLookup = async () => {
    setSearchingLocation(true);
    try {
      const token = await checkToken();
      const response = await axiosInstance(token).post('/location_lookup/', { location: locationSearch });
      setIntegrationEditor({
        ...integrationEditor,
        street_address: response.data.body[0].formatted_address,
        billing_address: response.data.body[0].formatted_address,
        coordinates: {
          ...integrationEditor.coordinates,
          lat: response.data.body[0].geometry.location.lat,
          long: response.data.body[0].geometry.location.lng
        }
      });
    } catch (error) {
      console.error('Error fetching location data:', error);
    } finally {
      setSearchingLocation(false);
    }
  };


  const handleKeyPress = async (e) => {
    if (e.key === 'Enter') {
      await handleLocationLookup();
    }
  };

  const handleSearchData = async (event) => {
    setLoadingData(true);
    if (event.key === 'Enter') {
      setSearchTerm(event.target.value);
      if (currentView === "orders" && selectedSubView === "Header") {
        const filteredOrders = await fetchOrdersV2(event.target.value, integration.id);
        setOrderRows(filteredOrders);
        setLoadingData(false);
      } else if (currentView === "orders" && selectedSubView === "Itemized") {
        const filteredDetails = await fetchOrderDetailsV2(event.target.value, integration.id);
        setDetailsRows(filteredDetails);
        setLoadingData(false);
      } else if (currentView === "documents") {
        const filteredOrders = await fetchDocumentsV2(event.target.value, null, "False", integration.id);
        setDocumentRows(filteredOrders);
        setLoadingData(false);
      } else if (currentView === "incoming" && selectedSubView === "Header") {
        const filteredOrders = await fetchOrdersV2(event.target.value, null, integration.id);
        setOrderRows(filteredOrders);
        setLoadingData(false);
      } else if (currentView === "incoming" && selectedSubView === "Itemized") {
        const filteredDetails = await fetchOrderDetailsV2(event.target.value, null, integration.id);
        setDetailsRows(filteredDetails);
        setLoadingData(false);
      }
    }
  };

  const handleDownload = async (msg_id, document_name) => {
    const data = await fetchDocumentContent(msg_id, document_name);
    const binaryData = atob(data);
    const byteNumbers = new Uint8Array(binaryData.length);
    for (let i = 0; i < binaryData.length; i++) {
      byteNumbers[i] = binaryData.charCodeAt(i);
    }

    const blob = new Blob([byteNumbers]);
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', document_name);
    document.body.appendChild(link);
    link.click();

    link.remove();
    window.URL.revokeObjectURL(url);
  };

  const handleFetchQuickbooksData = async () => {
    try {
      let data;
      if (integration.integration_type.type === "Customer") {
        data = await fetchQuickbooksCustomers();
      } else {
        data = await fetchQuickbooksVendors();
      }
      return data;
    } catch (error) {
      console.error('Error fetching data:', error);
      setSnackbarAlert("error");
      setSnackbarMessage("Error fetching data from QuickBooks. Try re-linking your account.");
      setSnackbarOpen(true);
      return [];
    }
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="xl"
        BackdropProps={{
          sx: {
            backgroundColor: "rgba(0, 0, 0, 0.1)",
          },
        }}
      >
        <Box sx={{ px: '1rem', pt: '1rem', display: 'flex', justifyContent: 'space-between' }}>
          <Box width={'100%'}>
            {!isEditing ? (
              <>
                <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ display: 'flex', alignItems: 'center', ml: '0.5rem', mb: 0, color: '#2b2b2b' }}
                    component={'div'}>
                    <strong>{integration.name} - {integration.integration_type.type} {integration.is_3pl ? ' and 3PL' : ''} {integration.is_supplier ? ' and Supplier' : ''}</strong>
                    <IconButton onClick={handleStartEditing} sx={{ ml: 1, verticalAlign: 'middle' }}>
                      <EditIcon sx={{ color: '#2b2b2b' }} />
                    </IconButton>
                    <TextField
                      type="color"
                      size='small'
                      value={integration.color_code}
                      onChange={(e) => {
                        const newColor = e.target.value;
                        setIntegration({ ...integration, color_code: newColor });
                      }}
                      onBlur={async () => {
                        setIntegrations((prevIntegrations) =>
                          prevIntegrations.map((int) =>
                            int.id === integration.id ? { ...int, color_code: integration.color_code } : int
                          )
                        );
                        await updateIntegration(integration.id, { ...integration, color_code: integration.color_code });
                      }}
                      sx={{
                        width: 50,
                        '& .MuiInputBase-root': {
                          padding: 0,
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                          border: 'none',
                        },
                      }}
                    />
                  </Typography>
                  <Box display={'flex'} flexDirection={'row'} alignItems={'center'} gap={'1rem'}>
                    {integration.integration_type.type !== "HQ" && hasQbLinked && (
                      <QuickBooksPartnerSelect
                        integration={integration}
                        setIntegrations={setIntegrations}
                        handleFetchQuickbooksData={handleFetchQuickbooksData}
                        qbType={integration.integration_type.type === "Customer" ? "Customer" : "Vendor"}
                      />
                    )}
                    <IconButton onClick={handleClose}>
                      <CloseIcon color="error" />
                    </IconButton>
                  </Box>
                </Box>
                {integration.street_address && (
                  <Typography variant="body2" gutterBottom style={{ display: 'flex', alignItems: 'center', color: '#2b2b2b' }}>
                    <PlaceIcon style={{ marginRight: '0.25em' }} />
                    {integration.street_address}
                  </Typography>
                )}
                <Typography variant="body2" gutterBottom style={{ display: 'flex', alignItems: 'center', color: '#2b2b2b' }}>
                  <EmailIcon style={{ marginRight: '0.25em' }} />
                  {integration.contact_name}
                  {' '}
                  {integration.contact_email}
                </Typography>
                {pinnedDocs.length > 0 && (
                  <Typography
                    variant="body2"
                    gutterBottom
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      color: '#2b2b2b',
                    }}
                  >
                    <PushPinIcon style={{ marginRight: '0.25em' }} />
                    {pinnedDocs.map((doc, index) => (
                      <Typography
                        key={index}
                        variant='body2'
                        onClick={() => handleDownload(doc.msg_id, doc.document_name)}
                        sx={{ cursor: 'pointer', textDecoration: 'underline', marginLeft: '0.5em' }}
                        component="span"
                      >
                        {doc.document_name}
                      </Typography>
                    ))}
                  </Typography>
                )}
              </>
            ) : (
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', m: 0 }}>
                  <TextField
                    label="Name"
                    value={integrationEditor.name}
                    onChange={(e) => setIntegrationEditor({ ...integrationEditor, name: e.target.value })}
                    size="small"
                    sx={{ mr: '5px' }}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<SaveIcon />}
                    onClick={handleSave}
                  >
                    Save
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    sx={{ ml: '5px' }}
                    onClick={() => setIsEditing(false)}
                  >
                    Cancel
                  </Button>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', mt: '0.75rem' }}>
                  <TextField
                    label="Search Location"
                    autoComplete='off'
                    size="small"
                    sx={{ mr: '5px', minWidth: '250px' }}
                    onChange={(e) => setLocationSearch(e.target.value)}
                    onKeyPress={handleKeyPress}
                  />
                  {searchingLocation ? (
                    <CircularProgress size={20} sx={{ mr: '5px' }} />
                  ) : (
                    <Button
                      variant="outlined"
                      color="primary"
                      startIcon={<SearchIcon />}
                      sx={{ mr: '5px' }}
                      onClick={async () => await handleLocationLookup()}
                    >
                      Search
                    </Button>
                  )}
                  <TextField
                    label="Street Address"
                    disabled
                    value={integrationEditor.street_address}
                    size="small"
                    sx={{ mr: '5px', minWidth: '400px' }}
                  />
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', mt: '0.75rem' }}>
                  <TextField
                    label="Contact Name"
                    value={integrationEditor.contact_name}
                    sx={{ mr: '5px' }}
                    onChange={(e) => setIntegrationEditor({ ...integrationEditor, contact_name: e.target.value })}
                    size="small"
                  />
                  <TextField
                    label="Contact Email"
                    value={integrationEditor.contact_email}
                    sx={{ mr: '5px' }}
                    onChange={(e) => setIntegrationEditor({ ...integrationEditor, contact_email: e.target.value })}
                    size="small"
                  />
                </Box>
              </Box>
            )}
            <Box sx={{ mt: 'auto', my: '0.5rem', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', gap: '0.5rem' }}>
              <ToggleDetailsViewV2
                integrationType={integration.integration_type.type}
                isBrandOperated={integration.is_brand_operated}
                currentView={currentView}
                setCurrentView={setCurrentView}
              />
              {!["product", "material"].includes(currentView) && (
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: '0.5rem', width: '35%' }}>
                  <TextField
                    variant="outlined"
                    size="small"
                    autoComplete='off'
                    value={searchTerm}
                    placeholder="Search"
                    onChange={(e) => setSearchTerm(e.target.value)}
                    fullWidth
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        handleSearchData(e);
                      }
                    }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '10px',
                        bgcolor: 'white',
                      },
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              )}
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', px: '1rem', pb: '1.5rem', width: '100%' }}>
          {currentView === 'documents' ? (
            <DocumentsV2
              rows={docmentRows}
              setRows={setDocumentRows}
              integrations={integrations}
              loading={loadingData}
              height='65vh'
              setSnackbarOpen={setSnackbarOpen}
              setSnackbarMessage={setSnackbarMessage}
              setSnackbarAlert={setSnackbarAlert}
              inputIntegrationId={integration.id}
            />
          ) : (['orders', 'incoming'].includes(currentView) && selectedSubView === 'Header') ? (
            <OrdersV2
              rows={orderRows}
              setRows={setOrderRows}
              integrations={integrations}
              selectedSubView={selectedSubView}
              setSelectedSubView={setSelectedSubView}
              loading={loadingData}
              height='65vh'
              setSnackbarOpen={setSnackbarOpen}
              setSnackbarMessage={setSnackbarMessage}
              setSnackbarAlert={setSnackbarAlert}
              hasQbLinked={hasQbLinked}
              detailHeight={'31vh'}
            />
          ) : (['orders', 'incoming'].includes(currentView) && selectedSubView === 'Itemized') ? (
            <OrderDetailsV2
              rows={detailRows}
              loading={loadingData}
              selectedSubView={selectedSubView}
              setSelectedSubView={setSelectedSubView}
              height='65vh'
            />
          ) : (
            <InventoryV2
              integration={integration}
              rows={inventoryRows}
              setRows={setInventoryRows}
              currentView={currentView}
              loading={loadingData}
              height='65vh'
              setSnackbarOpen={setSnackbarOpen}
              setSnackbarMessage={setSnackbarMessage}
              setSnackbarAlert={setSnackbarAlert}
            />
          )}
        </Box>
      </Dialog >
    </>
  );
};


export default IntegrationDialogViewV2;
