import * as React from 'react';
import {
  Box,
  TextField,
  InputAdornment,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
  Grid,
  Card,
  CardContent,
  Divider,
  Button
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import OilBarrelIcon from '@mui/icons-material/OilBarrel';
import WidgetsIcon from '@mui/icons-material/Widgets';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import DeleteIcon from '@mui/icons-material/Delete';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { fetchQuery, createQuery, deleteQuery } from '../api/queryV2';

export default function AgentComponent() {
  const [searchTerm, setSearchTerm] = React.useState('');
  const [searchType, setSearchType] = React.useState('Supplier');
  const [cards, setCards] = React.useState([]);

  React.useEffect(() => {
    const fetchQueryHeader = async () => {
      try {
        const response = await fetchQuery();
        setCards(response);
      } catch (error) {
        console.error('Error fetching cards:', error);
      }
    };
    fetchQueryHeader();
  }, []);

  const handleSearchData = async (e) => {
    e.preventDefault();
    if (searchTerm.trim()) {
      const insertQuery = await createQuery({ search_term: searchTerm, search_type: searchType });
      setCards((prevCards) => [
        insertQuery,
        ...prevCards,
      ]);
    }
    setSearchTerm('');
  };

  const handleDeleteQuery = async (id) => {
    await deleteQuery(id);
    setCards((prevCards) => prevCards.filter((card) => card.id !== id));
  };

  const handleSearchType = (event, newType) => {
    setSearchType(newType);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 1,
        height: '100%',
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          p: 1,
          width: '80%',
        }}
      >
        <Box
          display={'flex'}
          alignItems={'center'}
          flexDirection={'row'}
          justifyContent={'space-between'}
          width={'100%'}
          px={1}
        >
          <Typography
            variant="body1"
            color="inherit"
            fontWeight={600}
          >
            Use Trinity to help you source new partners.
          </Typography>
          <ToggleButtonGroup
            value={searchType}
            exclusive
            size='small'
            onChange={handleSearchType}
            aria-label="search_type"
          >
            <ToggleButton value="Supplier" aria-label="Supplier" sx={{ textTransform: 'none', py: 0.4 }}>
              <OilBarrelIcon fontSize='small' sx={{ mr: 1 }} />
              Supplier
            </ToggleButton>
            <ToggleButton value="Manufacturer" aria-label="Manufacturer" sx={{ textTransform: 'none', py: 0.4 }} disabled>
              <WidgetsIcon fontSize='small' sx={{ mr: 1 }} />
              Manufacturer
            </ToggleButton>
            <ToggleButton value="3PL" aria-label="3PL" sx={{ textTransform: 'none', py: 0.4 }} disabled>
              <LocalShippingIcon fontSize='small' sx={{ mr: 1 }} />
              3PL
            </ToggleButton>
            <ToggleButton value="Customer" aria-label="Customer" sx={{ textTransform: 'none', py: 0.4 }} disabled>
              <AttachMoneyIcon fontSize='small' sx={{ mr: 1 }} />
              Customer
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
        <Box
          display={'flex'}
          alignItems={'center'}
          flexDirection={'row'}
          justifyContent={'space-between'}
          width={'100%'}
          gap={1}
        >
          <TextField
            variant="outlined"
            size="small"
            autoComplete='off'
            value={searchTerm}
            placeholder={"Search"}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleSearchData(e);
              }
            }}
            fullWidth
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '10px',
                bgcolor: 'white',
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            size="small"
            onClick={handleSearchData}
            disabled={!searchTerm}
            endIcon={<PlayArrowIcon fontSize='small' />}
          >
            Go
          </Button>
        </Box>
      </Box>
      <Divider width={'80%'} />

      <Box
        sx={{
          width: '80%',
          height: '100%',
          p: 1,
          overflowY: 'auto',
          '&::-webkit-scrollbar': {
            backgroundColor: 'transparent',
            width: '0.3em',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#ccc',
            borderRadius: 10,
          },
        }}
      >
        <Grid container spacing={1.5} alignItems="flex-start">
          {cards.map((card) => (
            <Grid item xs={12} sm={6} key={card.id}>
              <Card sx={{ boxShadow: 'none', bgcolor: 'grey.300', border: '1px solid #ccc', borderRadius: '10px' }}>
                <CardContent>
                  <Typography variant="subtitle2" color="text.secondary" display={'flex'} alignItems={'center'} justifyContent={'space-between'} component={'div'}>
                    {card.search_type}
                    <DeleteIcon fontSize='small' color='error' sx={{ cursor: 'pointer' }} onClick={() => handleDeleteQuery(card.id)} />
                  </Typography>
                  <Typography variant="body1" fontWeight={600} sx={{ textDecoration: 'underline', cursor: 'pointer' }}>
                    {card.search_term}
                  </Typography>
                  <Typography variant="caption" color="text.secondary" sx={{ fontSize: '0.65rem' }}>
                    {card.create_time}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}
