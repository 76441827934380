import React, { useEffect } from 'react';
import {
  Box,
  CircularProgress
} from '@mui/material';
import trinity_logo from './assets/logo.png';
import axios from 'axios';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import config from './amplifyconfiguration.json';
import { ThemeProvider, CssBaseline } from '@mui/material';
import EmailClient from './pages/EmailClient';
import theme from './theme/theme';
import './theme/global.css';
import { Header } from "./components/AmplifyHeader";
import { fetchAuthSession } from 'aws-amplify/auth';
import { fetchIntegrationTypes, fetchIntegrations } from './api/integrations';
import { fetchSources } from './api/notifications';
import MobilePage from './components/MobilePage';
import { isMobile } from 'react-device-detect';


Amplify.configure(config);

function App({ signOut, user }) {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [isInitialized, setIsInitialized] = React.useState(false);
  const [userObj, setUserObj] = React.useState(null);
  const [integrations, setIntegrations] = React.useState([]);
  const [dataSources, setDataSources] = React.useState([]);
  const [integrationTypes, setIntegrationTypes] = React.useState([]);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const session = await fetchAuthSession();
        const userData = await axios.get(`${apiUrl}/user/`, {
          headers: {
            Authorization: `Bearer ${session.tokens.idToken.toString()}`
          }
        });
        setUserObj(userData.data);
        localStorage.setItem('identityId', session.identityId.toString());
        localStorage.setItem('orgId', userData.data?.org_id?.toString() || '');
        localStorage.setItem('apiToken', session.tokens.idToken.toString());
        const integrations_data = await fetchIntegrations();
        const integration_types_data = await fetchIntegrationTypes();
        const sources_data = await fetchSources();
        setDataSources(sources_data);
        setIntegrations(integrations_data);
        setIntegrationTypes(integration_types_data);
      } catch (error) {
        console.error('Error fetching content:', error);
        return;
      } finally {
        setIsInitialized(true);
      }
    };

    fetchUser();
  }, [user, apiUrl]);

  if (!isInitialized) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh', bgcolor: '#2b2b2b' }}>
        <img src={trinity_logo} alt="Trinity Logo" style={{ width: '125px' }} />
        <CircularProgress size={20} sx={{ color: 'white', mt: '0.5rem' }} />
      </Box>
    );
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <Routes>
            <Route path="/" element={
              isMobile ? (
                <MobilePage signOut={signOut} />
              ) : (
                <EmailClient
                  user={userObj}
                  setUser={setUserObj}
                  integrations={integrations}
                  setIntegrations={setIntegrations}
                  dataSources={dataSources}
                  setDataSources={setDataSources}
                  integrationTypes={integrationTypes}
                  signOut={signOut}
                />
              )}
            />
          </Routes>
        </Router>
      </ThemeProvider>
    </>
  );
}

export default withAuthenticator(App, {
  components: {
    SignIn: {
      Header: Header,
    }
  },
  socialProviders: ['google']
});