import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, Grid, Button, Box, Typography, Link, TextField, Switch, FormControlLabel, Avatar } from '@mui/material';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import UploadIcon from '@mui/icons-material/Upload';
import { getOrg, getLogo, inviteUser, toggleDailyDigest, createLogo } from '../api/users.js';


const SettingsDialog = ({
  user,
  setUser,
  open,
  onClose,
  setSnackbarOpen,
  setSnackbarMessage,
  setSnackbarAlert
}) => {
  const [currentView, setCurrentView] = useState("user");
  const [orgMembers, setOrgMembers] = useState([]);
  const [addingUser, setAddingUser] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [newUserEmail, setNewUserEmail] = useState("");
  const [logo, setLogo] = useState(null);

  useEffect(() => {
    if (open) {
      setCurrentView("user");
      setIsSubscribed(user.receive_daily_digest);

      const fetchSettingsData = async () => {
        try {
          const org = await getOrg();
          const base64String = await getLogo();
          const byteCharacters = atob(base64String);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: 'image/png' });
          const url = URL.createObjectURL(blob);
          setLogo(url);
          setOrgMembers(org);
        } catch (error) {
          console.error("Error fetching organization:", error);
        }
      };

      fetchSettingsData();
    }
  }, [open, user]);

  const handleInviteUser = async () => {
    try {
      await inviteUser(newUserEmail);
      setOrgMembers([...orgMembers, { email: newUserEmail }]);
      setNewUserEmail("");
      setAddingUser(false);
    } catch (error) {
      console.error("Error inviting user:", error);
      setSnackbarAlert("error");
      setSnackbarOpen(true);
      setSnackbarMessage(error.response.data.detail);
    }
  };

  const handleToggle = async () => {
    try {
      setIsSubscribed(!isSubscribed);
      await toggleDailyDigest();
      setUser({ ...user, receive_daily_digest: !user.receive_daily_digest });
    } catch (error) {
      console.error("Error toggling daily digest:", error);
      setSnackbarAlert("error");
      setSnackbarOpen(true);
      setSnackbarMessage(error.response.data.detail);
    }
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type === 'image/png') {
      uploadLogo(file);
    } else {
      setSnackbarAlert('error');
      setSnackbarOpen(true);
      setSnackbarMessage('Only PNGs are allowed.');
    }
  };

  const uploadLogo = async (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async () => {
      try {
        const b64encodedString = reader.result.split(',')[1];
        await createLogo(b64encodedString);

        const url = URL.createObjectURL(file);
        setLogo(url);
      } catch (error) {
        setSnackbarAlert('error');
        setSnackbarOpen(true);
        setSnackbarMessage('Error uploading logo.');
      }
    };
  };


  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      BackdropProps={{
        sx: {
          backgroundColor: "rgba(0, 0, 0, 0.1)",
        },
      }}
    >
      <DialogContent sx={{ height: '45vh', maxHeight: '45vh', display: 'flex', flexDirection: 'column' }}>
        <Grid container sx={{ height: '100%' }}>
          <Grid item xs={3}>
            <Box
              display="flex"
              flexDirection="column"
              gap={1}
              height="100%"
            >
              <Button variant="text" fullWidth onClick={() => setCurrentView("user")}>
                User Management
              </Button>
              <Button variant="text" fullWidth onClick={() => setCurrentView("brand")}>
                Brand / Logo
              </Button>
              <Button variant="text" fullWidth onClick={() => setCurrentView("feedback")}>
                Feedback
              </Button>
            </Box>
          </Grid>
          <Grid item xs={9}>
            <Box padding={1} height="100%">
              {currentView === "user" && (
                <Box display={"flex"} flexDirection="column" width={"100%"} alignItems={"center"}>
                  <Box sx={{ height: "100%", width: '70%' }} display={"flex"} flexDirection="column">
                    <Typography variant="body1" fontWeight={"bold"} component={"div"}>
                      Users in your account
                    </Typography>
                    {orgMembers.map((item, index) => (
                      <Typography key={index} variant="body2" mt={1}>
                        {item.email}
                      </Typography>
                    ))}
                    <FormControlLabel
                      control={
                        <Switch
                          checked={isSubscribed}
                          onChange={handleToggle}
                        />
                      }
                      label={
                        <Typography variant="body2">
                          Subscribe to daily updates in your inbox
                        </Typography>
                      }
                      sx={{ mt: 1 }}
                    />
                    {!addingUser ? (
                      <>
                        <Button
                          variant="outlined"
                          size='small'
                          startIcon={<PersonAddIcon />}
                          sx={{ mt: 1, width: '40%' }}
                          onClick={() => setAddingUser(true)}
                        >
                          Add User
                        </Button>
                        <Typography variant="body2" mt={0.5}>
                          New users will automatically join your account when they sign in.
                        </Typography>
                      </>
                    ) : (
                      <Box display={"flex"} flexDirection={"row"} gap={0.5} mt={2}>
                        <TextField
                          sx={{ width: '50%' }}
                          autoComplete="off"
                          label="Email"
                          size="small"
                          value={newUserEmail}
                          onChange={(event) => setNewUserEmail(event.target.value)}
                        />
                        <Button variant="contained" size='small' sx={{ width: '15%' }} onClick={handleInviteUser}>
                          Add
                        </Button>
                        <Button variant="outlined" size='small' sx={{ width: '15%' }} onClick={() => setAddingUser(false)}>
                          Cancel
                        </Button>
                      </Box>
                    )}
                  </Box>
                </Box>
              )}
              {currentView === "brand" && (
                <Box display={"flex"} flexDirection="column" width={"100%"} alignItems={"center"} justifyContent={"center"} height={"100%"}>
                  <Box sx={{ height: "100%", width: '70%' }} display={"flex"} flexDirection="column" alignItems={"left"} justifyContent={"flex-start"}>
                    <Typography variant="body1" fontWeight={"bold"} component={"div"}>
                      Add your logo to brand PDFs generated within Trinity
                    </Typography>
                    {logo ? (
                      <>
                        <Avatar
                          sx={{
                            bgcolor: 'transparent',
                            width: 'auto',
                            height: 'auto',
                            maxWidth: '125px',
                            maxHeight: '125px',
                            borderRadius: '5px',
                            my: '0.5rem',
                            objectFit: 'contain',
                            cursor: 'pointer',
                          }}
                          alt="logo"
                          src={logo}
                          onClick={() => document.getElementById('file-input').click()}
                        />
                        <Typography variant="subtitle2" component={"div"}>
                          Click logo to change
                        </Typography>
                      </>
                    ) : (
                      <Button
                        variant="outlined"
                        size='small'
                        sx={{ mt: 2, width: '40%' }}
                        startIcon={<UploadIcon />}
                        onClick={() => document.getElementById('file-input').click()}
                      >
                        Add Logo (png)
                      </Button>
                    )}
                    <input
                      type="file"
                      onChange={handleFileChange}
                      style={{ display: 'none' }}
                      id="file-input"
                    />

                  </Box>
                </Box>
              )}
              {currentView === "feedback" && (
                <Box display={"flex"} flexDirection="column" width={"100%"} alignItems={"center"} justifyContent={"center"} height={"100%"}>
                  <Box sx={{ height: "100%", width: '70%' }} display={"flex"} flexDirection="column">
                    <Typography variant="body1" fontWeight={"bold"} display="flex" alignItems={"center"} component={"div"}>
                      Send feedback to our team: paul@trinity3.co
                      <ContentCopyIcon fontSize="small" onClick={() => navigator.clipboard.writeText("paul@trinity3.co")} sx={{ ml: 1, cursor: 'pointer' }} />
                    </Typography>
                    <Typography variant="body2" paragraph>
                      Or you can{' '}
                      <Link href="https://www.trinity3.co/demo" target="_blank" underline="hover" sx={{ color: 'blue' }}>
                        schedule a call here.
                      </Link>
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default SettingsDialog;
