import React from 'react';
import {
  Box,
  IconButton,
  Select,
  MenuItem,
  Tooltip,
  Typography,
  Button
} from '@mui/material';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import { format, parseISO, isValid, parse } from 'date-fns';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import quickbooks_logo from '../../assets/icons8-quickbooks-32.png';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import AddIcon from '@mui/icons-material/Add';
import { deleteOrderV2, updateOrderV2, createOrderV2 } from '../../api/ordersV2.js'
import { createQuickbooksInvoice, createQuickbooksBill } from '../../api/integrations.js'
import OrderDetailViewer from '../OrderDetailViewer';
import ToggleOrdersViewV2 from '../../components/ToggleOrdersViewV2.js';

export default function OrdersV2({
  rows,
  setRows,
  integrations,
  selectedSubView,
  setSelectedSubView,
  setSnackbarAlert,
  setSnackbarOpen,
  setSnackbarMessage,
  loading,
  hasQbLinked,
  height = "86vh",
  detailHeight = "53vh"
}) {
  const [selectedOrder, setSelectedOrder] = React.useState("");

  const handleDeleteOrder = async (id) => {
    try {
      await deleteOrderV2(id);
      const updatedRows = rows.filter((row) => row.id !== id);
      setRows(updatedRows);
      setSnackbarOpen(true);
      setSnackbarMessage('Order deleted successfully.');
      setSnackbarAlert('success');
    } catch (error) {
      setSnackbarOpen(true);
      setSnackbarMessage('Error deleting order.');
      setSnackbarAlert('error');
    }
  };

  const handleProcessRowUpdate = async (updatedRow, oldRow) => {
    const processedRow = { ...updatedRow };
    if (processedRow.order_date && processedRow.order_date !== oldRow.order_date) {
      const parsedDate = parse(processedRow.order_date, 'MM/dd/yyyy', new Date());

      if (isValid(parsedDate)) {
        processedRow.order_date = format(parsedDate, 'yyyy-MM-dd');
      } else {
        console.error('Invalid date format:', processedRow.order_date);
        setSnackbarOpen(true);
        setSnackbarMessage('Invalid date format. Please use MM/dd/yyyy.');
        setSnackbarAlert('error');
        throw new Error('Invalid date format');
      }
    }
    await updateOrderV2(processedRow.id, processedRow);
    const updatedRows = rows.map((row) => (row.id === processedRow.id ? processedRow : row));
    setRows(updatedRows);
    return processedRow;
  };

  const handleCreateQuickBooksInvoice = async (id) => {
    try {
      await createQuickbooksInvoice(id);
      setSnackbarOpen(true);
      setSnackbarMessage('Invoice created successfully.');
      setSnackbarAlert('success');
    } catch (error) {
      console.error('Error:', error);
      const errorMessage = error.response?.data?.detail || 'Error creating invoice. Make sure this partner is linked to a QuickBooks customer.';
      setSnackbarOpen(true);
      setSnackbarMessage(errorMessage);
      setSnackbarAlert('error');
    }
  }

  const handleCreateQuickBooksBill = async (id) => {
    try {
      await createQuickbooksBill(id);
      setSnackbarOpen(true);
      setSnackbarMessage('Bill created successfully.');
      setSnackbarAlert('success');
    } catch (error) {
      console.error('Error:', error);
      const errorMessage = error.response?.data?.detail || 'Error creating bill. Make sure this partner is linked to a QuickBooks vendor.';
      setSnackbarOpen(true);
      setSnackbarMessage(errorMessage);
      setSnackbarAlert('error');
    }
  };

  const handleCreateOrder = async () => {
    try {
      const dummy_order = {
        integration_id: integrations.find(integration => integration.integration_type.category === 'HQ').id,
        status: 'PO Sent',
        order_name: `PO-${format(new Date(), 'yyyyMMdd_HHmmss')}`,
        order_date: format(new Date(), 'yyyy-MM-dd'),
        line_items: [{ item_name: '' }],
      }
      const order_created = await createOrderV2(dummy_order);
      const updatedRows = [order_created, ...rows];
      setRows(updatedRows);
    } catch (error) {
      console.error('Error:', error);
      setSnackbarOpen(true);
      setSnackbarMessage('Error creating order.');
      setSnackbarAlert('error');
    }
  };


  const columns = [
    { field: 'order_name', headerName: 'Order', flex: 1.25, editable: true },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      renderCell: (params) => {
        const handleChange = (event) => {
          const updatedStatus = event.target.value;
          const updatedRow = { ...params.row, status: updatedStatus };
          handleProcessRowUpdate(updatedRow, params.row);
        };
        return (
          <>
            {params.row.is_sales_order ? (
              <Typography variant="inherit">Received</Typography>
            ) : (
              <Select
                labelId="status-select-label"
                value={params.value}
                label="Status"
                variant='standard'
                fullWidth
                onChange={handleChange}
                sx={{ fontFamily: 'inherit', fontSize: 'inherit' }}
              >
                <MenuItem value="PO Sent">PO Sent</MenuItem>
                <MenuItem value="In Transit">In Transit</MenuItem>
                <MenuItem value="Delivered">Delivered</MenuItem>
              </Select>
            )}
          </>
        )
      }
    },
    {
      field: 'integration_id', headerName: 'Partner', flex: 1,
      renderCell: (params) => {
        const value = integrations.find((item) => item.id === params.value);

        const handleChange = (event) => {
          const updatedIntegrationId = event.target.value;
          const updatedRow = { ...params.row, integration_id: updatedIntegrationId };
          handleProcessRowUpdate(updatedRow, params.row);
        };
        return (
          <>
            <Select
              labelId="status-select-label"
              value={value.id}
              label="Status"
              variant='standard'
              fullWidth
              onChange={handleChange}
              sx={{
                fontFamily: 'inherit',
                fontSize: 'inherit',
                color: integrations.find(integration => params.row.integration_id === integration.id)?.color_code ? integrations.find(integration => params.row.integration_id === integration.id)?.color_code : 'inherit'
              }}
            >
              {integrations.map((integration) => (
                <MenuItem key={integration.id} value={integration.id}>
                  {integration.name}
                </MenuItem>
              ))}
            </Select>
          </>
        )
      },
    },
    {
      field: 'integration_id_to', headerName: 'Partner To', flex: 1,
      renderCell: (params) => {
        const value = integrations.find((item) => item.id === params.value);

        const handleChange = (event) => {
          const updatedIntegrationId = event.target.value;
          const updatedRow = { ...params.row, integration_id_to: updatedIntegrationId };
          handleProcessRowUpdate(updatedRow, params.row);
        };
        return (
          <>
            {params.row.is_sales_order ? (
              undefined
            ) : (
              <Select
                labelId="status-select-label"
                value={value?.id}
                label="Status"
                variant='standard'
                fullWidth
                onChange={handleChange}
                sx={{
                  fontFamily: 'inherit',
                  fontSize: 'inherit',
                  color: integrations.find(integration => params.row.integration_id_to === integration.id)?.color_code ? integrations.find(integration => params.row.integration_id_to === integration.id)?.color_code : 'inherit'
                }}
              >
                {integrations.map((integration) => (
                  <MenuItem key={integration.id} value={integration.id}>
                    {integration.name}
                  </MenuItem>
                ))}
              </Select>
            )}
          </>
        )
      },
    },
    {
      field: 'total_freight', headerName: 'Freight Cost', flex: 1, editable: true,
      renderCell: (params) => {
        const value = params.value;
        if (!value) {
          return '';
        }
        return `$${value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
      },
    },
    {
      field: 'header_detail',
      headerName: 'Total Cost',
      flex: 1,
      renderCell: (params) => {
        const value = params?.row?.header_detail;
        let totalCost = 0;

        if (Array.isArray(value)) {
          totalCost = value.reduce((sum, item) => {
            const itemPrice = parseFloat(item?.item_price) || 0;
            const orderQty = parseFloat(item?.order_qty) || 0;
            return sum + (itemPrice * orderQty);
          }, 0);
        }

        const freightCost = parseFloat(params?.row?.total_freight) || 0;
        totalCost += freightCost;

        if (totalCost === 0) {
          return '';
        }

        return totalCost.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
      },
    },
    {
      field: 'order_date', headerName: 'Order Date', width: 140,
      editable: true,
      renderCell: (params) => {
        const value = params.value;
        if (!value) {
          return '';
        }
        const date = parseISO(value);
        return format(date, 'MM/dd/yy');
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 140,
      align: 'right',
      headerAlign: 'center',
      renderCell: (params) => (
        <>
          {params.row.is_sales_order && hasQbLinked ? (
            <Tooltip title="Create QuickBooks Invoice">
              <IconButton
                key={`send-icon-${params.row.id}`}
                onClick={() => handleCreateQuickBooksInvoice(params.row.id)}
              >
                <img src={quickbooks_logo} alt="qb_logo" style={{ width: '24px', height: '24px' }} />
              </IconButton>
            </Tooltip>
          ) : hasQbLinked ? (
            <Tooltip title="Create QuickBooks Bill">
              <IconButton
                key={`send-icon-${params.row.id}`}
                onClick={() => handleCreateQuickBooksBill(params.row.id)}
              >
                <img src={quickbooks_logo} alt="qb_logo" style={{ width: '24px', height: '24px' }} />
              </IconButton>
            </Tooltip>
          ) : null}
          <Tooltip title="View order details">
            <IconButton
              key={`view-icon-${params.row.id}`}
              onClick={() => setSelectedOrder(params.row)}
            >
              <ViewInArIcon color='info' />
            </IconButton>
          </Tooltip>
          <IconButton
            key={`delete-icon-${params.row.id}`}
            onClick={() => handleDeleteOrder(params.row.id)}
          >
            <DeleteOutlineOutlinedIcon color='error' />
          </IconButton>
        </>
      )
    }
  ];

  const Toolbar = () => {
    return (
      <GridToolbarContainer>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%', mx: 0.5 }}>
          <ToggleOrdersViewV2
            currentSubView={selectedSubView}
            setCurrentSubView={setSelectedSubView}
          />
          <Button
            variant='outlined'
            sx={{ borderRadius: '10px' }}
            startIcon={<AddIcon />}
            onClick={handleCreateOrder}
          >
            Create Order
          </Button>
        </Box>
      </GridToolbarContainer>
    );
  };

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      bgcolor: 'white',
      p: selectedOrder ? 2 : 0,
      height: height,
      border: selectedOrder ? '1px solid #ccc' : undefined,
      maxHeight: height,
      width: '100%',
    }}>
      {!selectedOrder ? (
        <Box sx={{ width: '100%' }}>
          <DataGrid
            rows={rows}
            columns={columns}
            density="compact"
            loading={loading}
            disableRowSelectionOnClicks
            slots={{ toolbar: Toolbar }}
            processRowUpdate={handleProcessRowUpdate}
            hideFooter
          />
        </Box>
      ) : (
        <OrderDetailViewer
          order={selectedOrder}
          setOrder={setSelectedOrder}
          setOrderRows={setRows}
          dataGridHeight={detailHeight}
          setSnackbarOpen={setSnackbarOpen}
          setSnackbarMessage={setSnackbarMessage}
          setSnackbarAlert={setSnackbarAlert}
        />
      )}
    </Box>
  );
}
