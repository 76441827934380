import * as React from 'react';
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  Button,
  Skeleton,
  Tooltip
} from '@mui/material';
import CreateWorkflowDialog from '../components/CreateWorkflowDialog';
import WorkflowDetailDialog from '../components/WorkflowDetailDialog';
import { fetchWorkflow, deleteWorkflow } from '../api/workflowV2';
import InterestsIcon from '@mui/icons-material/Interests';
import CircleIcon from '@mui/icons-material/Circle';
import DeleteIcon from '@mui/icons-material/Delete';
import { format } from 'date-fns';
import { fromZonedTime } from 'date-fns-tz';

export default function WorkflowsComponent() {
  const [cards, setCards] = React.useState([]);
  const [loadingCards, setLoadingCards] = React.useState(false);
  const [selectedWorkflow, setSelectedWorkflow] = React.useState(null);
  const [workflowOpen, setWorkflowOpen] = React.useState(false);
  const [workflowDetailOpen, setWorkflowDetailOpen] = React.useState(false);

  React.useEffect(() => {
    const fetchWorkflows = async () => {
      try {
        setLoadingCards(true);
        const response = await fetchWorkflow();
        setCards(response);
      } catch (error) {
        console.error('Error fetching cards:', error);
      } finally {
        setLoadingCards(false);
      }
    };
    fetchWorkflows();
  }, []);

  const handleDeleteQuery = async (id) => {
    await deleteWorkflow(id);
    setCards((prevCards) => prevCards.filter((card) => card.id !== id));
  };


  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 1,
        height: '100%',
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          width: '80%',
          height: '100%',
          p: 1,
          overflowY: 'auto',
          '&::-webkit-scrollbar': {
            backgroundColor: 'transparent',
            width: '0.3em',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#ccc',
            borderRadius: 10,
          },
        }}
      >
        <Button
          variant='outlined'
          onClick={() => setWorkflowOpen(true)}
          startIcon={<InterestsIcon />}
          sx={{
            width: '25%',
            borderRadius: '10px',
            textTransform: 'none',
            mt: '0.25rem',
          }}
        >
          Create Workflow
        </Button>
        {loadingCards ? (
          <>
            <Skeleton variant="rounded" height={25} />
            <Skeleton variant="rounded" height={25} />
            <Skeleton variant="rounded" height={25} />
            <Skeleton variant="rounded" height={25} />
            <Skeleton variant="rounded" height={25} />
            <Skeleton variant="rounded" height={25} />
          </>
        ) : (
          <Grid container spacing={1.5} alignItems="flex-start">
            {cards.map((card) => (
              <Grid item xs={12} sm={6} key={card.id}>
                <Card sx={{ boxShadow: 'none', bgcolor: 'grey.300', border: '1px solid #ccc', borderRadius: '10px' }}>
                  <CardContent>
                    <Typography variant="subtitle2" color="text.secondary" display={'flex'} alignItems={'center'} justifyContent={'space-between'} component={'div'}>
                      {card.type}
                      <DeleteIcon fontSize='small' color='error' sx={{ cursor: 'pointer' }} onClick={() => handleDeleteQuery(card.id)} />
                    </Typography>
                    <Typography
                      variant="body1"
                      fontWeight={600}
                      component={'div'}
                      display={'flex'}
                      alignItems={'center'}
                      sx={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => { setSelectedWorkflow(card); setWorkflowDetailOpen(true) }}
                    >
                      {card.name}
                      {card.is_enabled ? (
                        <Tooltip title="Rule is enabled." placement="right">
                          <CircleIcon color='success' sx={{ ml: 1, fontSize: '1rem' }} />
                        </Tooltip>
                      ) : (
                        <Tooltip title="Rule is disabled." placement="right">
                          <CircleIcon color='error' sx={{ ml: 1, fontSize: '1rem' }} />
                        </Tooltip>
                      )}
                    </Typography>
                    <Typography variant="subtitle2" color="text.secondary">
                      {card.template.length} field(s)
                    </Typography>
                    <Typography variant="caption" color="text.secondary" sx={{ fontSize: '0.65rem' }}>
                      {(() => {
                        const date = new Date(card.create_time);
                        const zonedDate = fromZonedTime(date, 'UTC');
                        return format(zonedDate, 'MM/dd/yy hh:mm a');
                      })()}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
      <WorkflowDetailDialog
        open={workflowDetailOpen}
        onClose={() => {
          setWorkflowDetailOpen(false)
          setSelectedWorkflow(null)
        }}
        selectedWorkflow={selectedWorkflow}
        setSelectedWorkflow={setSelectedWorkflow}
        setCards={setCards}
      />
      <CreateWorkflowDialog
        dialogOpen={workflowOpen}
        onClose={() => setWorkflowOpen(false)}
        setCards={setCards}
      />
    </Box>
  );
}
