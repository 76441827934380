import React, { useCallback, useState } from 'react';
import { Popover, Box, Typography, TextField, Button, LinearProgress } from '@mui/material';
import PeopleIcon from '@mui/icons-material/People';
import SendIcon from '@mui/icons-material/Send';
import { sendDocs } from '../api/ordersV2.js';

const EmailPopover = ({ anchorEl, setAnchorEl, documents }) => {
  const handleClose = useCallback(() => setAnchorEl(null), [setAnchorEl]);
  const [toEmail, setToEmail] = useState("");
  const [subject, setSubject] = useState("Shared Documents from Trinity");
  const [ccList, setCcList] = useState("");
  const [loadSending, setLoadSending] = useState(false);
  const [emailBody, setEmailBody] = useState("Please find the documents attached.");

  const handleTriggerEmail = async () => {
    try {
      setLoadSending(true);
      await sendDocs(documents, subject, toEmail, ccList, emailBody);
      handleClose();
      setLoadSending(false);
    } catch (error) {
      console.error('Error sending shipment:', error);
      setLoadSending(false);
    }
  };

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
    >
      <Box
        sx={{
          width: '600px',
          height: '100%',
          p: '2rem',
          gap: '1rem',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          border: '1px solid black',
          borderRadius: '15px'
        }}
      >
        <Typography variant="body1" display={'flex'} alignItems={'center'}>
          <PeopleIcon sx={{ mr: '0.5rem' }} />
          <b>Share Documents</b>
        </Typography>
        <Typography variant="body2">
          <b>Email will be sent from no-reply@trinity3.co</b>
        </Typography>
        <TextField
          label="Subject"
          fullWidth
          autoComplete='off'
          size="small"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
        />
        <TextField
          label="To"
          fullWidth
          autoComplete='off'
          size="small"
          value={toEmail}
          onChange={(e) => setToEmail(e.target.value)}
        />
        <TextField
          label="CC (comma separated)"
          fullWidth
          autoComplete='off'
          size="small"
          value={ccList}
          onChange={(e) => setCcList(e.target.value)}
        />
        <TextField
          label="Email Message"
          fullWidth
          multiline
          rows={6}
          size="small"
          value={emailBody}
          onChange={(e) => setEmailBody(e.target.value)}
        />
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '0.5rem', width: '100%', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
          <Typography sx={{ color: 'black', fontSize: '0.875rem' }}>
            <b>Attached Documents:</b>
          </Typography>
          {documents.map((doc) => (
            <Typography key={doc.id} sx={{ color: 'black', fontSize: '0.875rem' }}>
              {doc.document_name}
            </Typography>
          ))}
        </Box>
        {loadSending ? (
          <LinearProgress sx={{ width: '100%', my: '1rem' }} />
        ) : (
          <Button
            variant="contained"
            onClick={handleTriggerEmail}
            disabled={!toEmail}
            startIcon={<SendIcon />}
          >
            Send
          </Button>
        )}
      </Box>
    </Popover>
  );
};

export default EmailPopover;
