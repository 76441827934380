import React, { useEffect, useState } from 'react';
import { Box, Typography, IconButton, Divider, Skeleton } from '@mui/material';
import { fetchInventoryAggregatesV2, fetchSalesAggregatesV2 } from '../api/inventoryV2';
import WidgetsIcon from '@mui/icons-material/Widgets';
import { format } from 'date-fns';

const MobileDashboard = () => {
  const [inventoryData, setInventoryData] = useState([]);
  const [salesData, setSalesData] = useState([]);
  const [toggleInventoryView, setToggleInventoryView] = useState(true);
  const [loadingData, setLoadingData] = useState(false);

  useEffect(() => {
    const initializeData = async () => {
      setLoadingData(true);
      const inventoryData = await fetchInventoryAggregatesV2();
      const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const salesData = await fetchSalesAggregatesV2(userTimeZone);
      setSalesData(salesData);
      setInventoryData(inventoryData);
      setLoadingData(false);
    };

    initializeData();
  }, []);

  const handleToggleInventoryView = async () => {
    setLoadingData(true);
    const data = await fetchInventoryAggregatesV2(toggleInventoryView);
    setToggleInventoryView(!toggleInventoryView);
    setInventoryData(data);
    setLoadingData(false);
  };


  return (
    <>

      <Box
        sx={{
          flex: '1',
          flexDirection: 'column',
          display: 'flex',
          alignItems: 'top',
          justifyContent: 'left',
          borderRadius: '10px',
          border: '1px solid #ccc',
          bgcolor: 'grey.200',
          mx: 1,
          my: 1,
          p: 1.5,
          overflowY: 'auto',
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="body2" color="grey.800" fontWeight={'bold'} component={'div'}>
            Inventory
            <IconButton color="inherit" onClick={handleToggleInventoryView} sx={{ m: 0, px: 0.5, py: 0 }}>
              <WidgetsIcon fontSize='small' />
            </IconButton>
          </Typography>
          <Typography variant="body2" sx={{ fontSize: "0.8rem" }} color="grey.800">
            {inventoryData && inventoryData.reduce((total, item) => total + Number(item.qty), 0).toLocaleString()} units
          </Typography>
        </Box>
        <Divider sx={{ my: 0.5, borderColor: 'grey.700' }} flexItem />
        {loadingData ? (
          <>
            <Skeleton variant="text" height={20} />
            <Skeleton variant="text" height={20} />
            <Skeleton variant="text" height={20} />
            <Skeleton variant="text" height={20} />
          </>
        ) : !toggleInventoryView ? (
          inventoryData &&
          Object.entries(
            inventoryData.reduce((acc, item) => {
              if (!acc[item.name]) {
                acc[item.name] = [];
              }
              acc[item.name].push(item);
              return acc;
            }, {})
          ).map(([name, items]) => (
            <div key={name} style={{ marginBottom: '16px' }}>
              <Typography variant="body2" sx={{ fontSize: "0.75rem" }} color="grey.800">
                <b>{name}</b>
              </Typography>
              {items.map((item, index) => (
                <Box
                  key={index}
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                    width: '100%',
                    paddingLeft: '12px',
                  }}
                >
                  <Typography variant="body2" sx={{ fontSize: "0.75rem" }} color="grey.800">
                    {item.item || ''}
                  </Typography>
                  <Typography variant="body2" sx={{ fontSize: "0.75rem" }} color="grey.800">
                    {Number(item.qty).toLocaleString()} units
                  </Typography>
                </Box>
              ))}
            </div>
          ))
        ) : (
          inventoryData &&
          inventoryData.map((item, index) => (
            <Box
              key={index}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <Typography variant="body2" sx={{ fontSize: "0.75rem" }} color="grey.800">
                <b>{item.name}</b>
              </Typography>
              {item.item && (
                <Typography variant="body2" sx={{ fontSize: "0.75rem", mx: 1 }} color="grey.800">
                  [{item.item}]
                </Typography>
              )}
              <Typography variant="body2" sx={{ fontSize: "0.75rem" }} color="grey.800">
                {Number(item.qty).toLocaleString()} units
              </Typography>
            </Box>
          ))
        )}
      </Box>

      {salesData && salesData.length > 0 && (
        <Box
          sx={{
            flex: '1',
            flexDirection: 'column',
            display: 'flex',
            alignItems: 'top',
            justifyContent: 'left',
            borderRadius: '10px',
            border: '1px solid #ccc',
            bgcolor: 'grey.200',
            mx: 1,
            mb: 1,
            p: 1.5,
            overflowY: 'auto',
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="body2" color="grey.800" fontWeight={'bold'} component={'div'}>
              Sales
            </Typography>
            <Typography variant="body2" sx={{ fontSize: "0.8rem" }} color="grey.800">
              Sales Today: ${salesData &&
                salesData
                  .reduce((total, item) => total + Number(item.total_amount), 0)
                  .toFixed(2)
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            </Typography>
          </Box>
          <Divider sx={{ my: 0.5, borderColor: 'grey.700' }} flexItem />
          {loadingData ? (
            <>
              <Skeleton variant="text" height={20} />
              <Skeleton variant="text" height={20} />
              <Skeleton variant="text" height={20} />
              <Skeleton variant="text" height={20} />
            </>
          ) : (
            salesData &&
            salesData.map((item, index) => (
              <React.Fragment key={index}>
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <Typography variant="body2" sx={{ fontSize: "0.75rem" }} color="grey.800">
                    <b>{item.source}</b>
                  </Typography>
                  <Typography variant="body2" sx={{ fontSize: "0.75rem" }} color="grey.800">
                    ${Number(item.total_amount)
                      .toFixed(2)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    {' '}
                    ({(Number(item.total_amount) / salesData.reduce((total, item) => total + Number(item.total_amount), 0) * 100).toFixed(1)}%)
                  </Typography>
                </Box>
                <Typography variant="body2" color="grey.800" align="left" sx={{ fontSize: '0.65rem', mt: 0.1 }}>
                  Updated: {format(new Date(item.last_updated), 'yyyy-MM-dd hh:mm:ss a')}
                </Typography>
              </React.Fragment>
            ))
          )}
        </Box>
      )}
    </>
  );
};

export default MobileDashboard;
