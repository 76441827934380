import { axiosInstance, checkToken } from './utils.js'



const fetchWorkflow = async () => {
  const url = `/v2/workflow/`;
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).get(url);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};


const fetchWorkflowRuns = async (id) => {
  const url = `/v2/workflow_runs/${id}`;
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).get(url);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

const deleteWorkflowRun = async (id) => {
  const url = `/v2/workflow_runs/${id}`;
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).delete(url);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};


const createWorkflow = async (workflow) => {
  const url = `/v2/workflow/`;
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).post(url, workflow);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

const updateWorkflow = async (workflow) => {
  const url = `/v2/workflow/`;
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).patch(url, workflow);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

const updateWorkflowRun = async (workflowRun) => {
  const url = `/v2/workflow_runs/`;
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).patch(url, workflowRun);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

const deleteWorkflow = async (id) => {
  const url = `/v2/workflow/${id}`;
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).delete(url);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};


export {
  fetchWorkflow,
  fetchWorkflowRuns,
  createWorkflow,
  updateWorkflow,
  updateWorkflowRun,
  deleteWorkflow,
  deleteWorkflowRun,
};
