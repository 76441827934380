import React, { useState, useEffect } from 'react';
import {
  Button,
  Typography,
  Grid,
  Divider,
  TextField,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Box,
  IconButton,
  CircularProgress
} from '@mui/material';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import ShareIcon from '@mui/icons-material/Share';
import CloseIcon from '@mui/icons-material/Close';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import InfoIcon from '@mui/icons-material/Info';
import AddIcon from '@mui/icons-material/Add';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import SaveIcon from '@mui/icons-material/Save';
import { fetchDocuments, fetchDocumentContent, analyzeOrder } from '../api/documents';
import { updateOrderDocumentsV2, unlinkDocumentV2, updateOrderV2, createLineItemV2, deleteLineItemV2, createPdf } from '../api/ordersV2';
import { fetchInventoryV2 } from '../api/inventoryV2.js';
import EmailPopover from '../components/EmailPopover.js';
import PDFViewer from '../components/PDFViewer.js';


const CustomTextField = ({ label, value, onChange, multiline, disabled = false, type = 'text' }) => (
  <TextField
    label={label}
    value={value || ''}
    fullWidth
    type={type}
    margin="dense"
    disabled={disabled}
    autoComplete='off'
    multiline={multiline}
    size="small"
    onChange={onChange}
    InputProps={{
      style: {
        borderRadius: '10px',
      },
    }}
  />
);

const OrderDetailViewer = ({
  order,
  setOrder,
  setOrderRows,
  setSnackbarOpen,
  setSnackbarMessage,
  setSnackbarAlert,
  blobTypeInput = null,
  blobUrlInput = null,
  dataGridHeight = '53vh'
}) => {
  const [documentOptions, setDocumentOptions] = useState([]);
  const [blobUrl, setBlobUrl] = useState("");
  const [blobType, setBlobType] = useState("");
  const [tempDoc, setTempDoc] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [itemDropdownOptions, setItemDropdownOptions] = useState([]);
  const [isChanged, setIsChanged] = React.useState(false);
  const [loadingPdf, setLoadingPdf] = useState(false);

  useEffect(() => {
    const initializeOrderDetail = async () => {
      if (itemDropdownOptions.length === 0) {
        const documents = await fetchDocuments();
        const inventory = await fetchInventoryV2(order.integration_id);
        setItemDropdownOptions(inventory);
        setDocumentOptions(documents.filter((doc) => doc.order_header_id === null && doc.order_detail_id === null));
      }

      if (blobUrlInput && blobTypeInput) {
        setBlobUrl(blobUrlInput);
        setBlobType(blobTypeInput);
      }
    };
    initializeOrderDetail();
  }, [blobUrlInput, blobTypeInput, order, itemDropdownOptions]);

  if (!order) return null;

  const handleSaveOrder = async () => {
    if (!isChanged) {
      setOrder("");
      return;
    }

    const selectedOrderWithParsableFloats = {
      ...order,
      total_freight: parseFloat(order.total_freight),
      total_tax: parseFloat(order.total_tax),
      total_cost: parseFloat(order.total_cost),
    };

    const data = await updateOrderV2(selectedOrderWithParsableFloats.id, selectedOrderWithParsableFloats);
    setOrderRows((prevRows) => prevRows.map((row) => (row.id === selectedOrderWithParsableFloats.id ? data : row)));
    setOrder("");
    setIsChanged(false);
  };


  const handleCloseOrder = async () => {
    setItemDropdownOptions([]);
    setDocumentOptions([]);
    setOrder("");
    setIsChanged(false);
  };

  const handleDeleteLineItem = async (id) => {
    setIsChanged(true);
    await deleteLineItemV2(id);
    setOrder((prevOrder) => ({
      ...prevOrder,
      header_detail: (prevOrder.header_detail || []).filter((item) => item.id !== id),
    }));
  };

  const handleCreateLineItem = async (order_header_id) => {
    setIsChanged(true);
    const createdItem = await createLineItemV2(order_header_id);
    setOrder((prevOrder) => ({
      ...prevOrder,
      header_detail: [
        ...(prevOrder.header_detail || []),
        { ...createdItem, is_new: true }
      ],
    }));
  };

  const columns = [
    {
      field: 'item_name',
      headerName: 'Item',
      flex: 2,
      headerClassName: 'super-app-theme--header-new',
      editable: true,
      renderCell: (params) => {
        return params.row.item_name === '' ? (
          <Select
            value={params.row.item_name || ''}
            size='small'
            variant='standard'
            fullWidth
            sx={{ fontFamily: 'inherit', fontSize: 'inherit' }}
            onChange={async (e) => {
              const selectedOption = e.target.value;
              const updatedRow = {
                ...params.row,
                item_name: selectedOption.item,
                item_price: selectedOption.unit_cost
              };
              await handleRowUpdate(updatedRow, params.row);
            }}
          >
            <MenuItem value="" disabled>Select an item</MenuItem>
            {itemDropdownOptions.map((option) => (
              <MenuItem key={option.id} value={option}>
                {option.item}
              </MenuItem>
            ))}
          </Select>
        ) : (
          <span>{params.row.item_name}</span>
        );
      },
    },
    { field: 'order_qty', headerName: 'Order Qty', flex: 1, type: 'number', headerClassName: 'super-app-theme--header-new', editable: true },
    { field: 'invoice_qty', headerName: 'Invoice Qty', flex: 1, type: 'number', headerClassName: 'super-app-theme--header-new', editable: true },
    { field: 'item_unit_of_measure', headerName: 'Unit of Measure', flex: 1, headerClassName: 'super-app-theme--header-new', editable: true },
    {
      field: 'item_price', headerName: 'Unit Price', flex: 1, headerClassName: 'super-app-theme--header-new', editable: true,
      renderCell: (params) => new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(params.value)
    },
    { field: 'lot_number', headerName: 'Lot', flex: 1, headerClassName: 'super-app-theme--header-new', editable: true },
    { field: 'expiration_date_raw', headerName: 'Exp. Date', flex: 1, headerClassName: 'super-app-theme--header-new', editable: true },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 75,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <IconButton key={`delete-icon-${params.row.id}`} onClick={() => handleDeleteLineItem(params.row.id)}>
            <DeleteOutlineOutlinedIcon color='error' />
          </IconButton>
        );
      },
      headerClassName: 'super-app-theme--header-new'
    }
  ];


  const handleTextFieldChange = (e, field) => {
    setIsChanged(true);
    const { value } = e.target;
    setOrder((prevOrder) => ({
      ...prevOrder,
      [field]: value,
    }));
  };

  const rows = (order?.header_detail || []).map((detail, index) => ({
    id: detail.id,
    item_name: detail.item_name,
    order_qty: detail.order_qty,
    invoice_qty: detail.invoice_qty,
    item_price: detail.item_price,
    item_unit_of_measure: detail.item_unit_of_measure,
    lot_number: detail.lot_number,
    expiration_date_raw: detail.expiration_date_raw,
  }));

  const handleRowUpdate = (newRow, oldRow) => {
    setIsChanged(true);
    const updatedRow = {
      id: newRow.id,
      item_name: newRow.item_name,
      order_qty: newRow.order_qty !== "" ? parseFloat(newRow.order_qty) : null,
      invoice_qty: newRow.invoice_qty !== "" ? parseFloat(newRow.invoice_qty) : null,
      item_price: newRow.item_price !== "" ? parseFloat(newRow.item_price) : null,
      item_unit_of_measure: newRow.item_unit_of_measure || null,
      lot_number: newRow.lot_number || null,
      expiration_date_raw: newRow.expiration_date_raw || null,
    };
    const updatedRows = rows.map((row) =>
      row.id === oldRow.id ? { ...row, ...updatedRow } : row
    );
    setOrder((prevOrder) => ({
      ...prevOrder,
      header_detail: updatedRows,
    }));
    return newRow;
  };

  const handleMapDocument = async (selectedDoc) => {
    await updateOrderDocumentsV2(order.id, selectedDoc.id)
    const newDocumentEntry = {
      id: selectedDoc.id,
      order_header_id: order.id,
      integration_id: selectedDoc.integration_id,
      category: selectedDoc.category,
      msg_id: selectedDoc.msg_id,
      document_name: selectedDoc.document_name,
    };

    setOrder((prevOrder) => ({
      ...prevOrder,
      header_document: [...(prevOrder.header_document || []), newDocumentEntry],
    }));

    setTempDoc(null);
  };

  const handleFetchDocument = async (row) => {
    setBlobUrl("");
    setBlobType("");
    try {
      const data = await fetchDocumentContent(row.msg_id, row.document_name);
      const pdfBase64 = data;
      const byteCharacters = atob(pdfBase64);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      let mimeType = 'application/octet-stream';
      if (row.document_name.endsWith('.pdf')) {
        mimeType = 'application/pdf';
      } else if (row.document_name.endsWith('.png')) {
        mimeType = 'image/png';
      } else if (row.document_name.endsWith('.jpg') || row.document_name.endsWith('.jpeg')) {
        mimeType = 'image/jpeg';
      } else if (row.document_name.endsWith('.zip')) {
        mimeType = 'application/zip';
      } else if (row.document_name.endsWith('.xlsx')) {
        mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      }

      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: mimeType });

      if (mimeType === 'application/zip' || mimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', row.document_name);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      } else {
        setBlobType(mimeType);
        if (mimeType !== 'application/pdf') {
          const url = URL.createObjectURL(blob);
          setBlobUrl(url);
        } else {
          setBlobUrl(blob);
        }
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleUnlinkDocument = async (row) => {
    setIsChanged(true);
    await unlinkDocumentV2(row.id);
    setOrder((prevOrder) => ({
      ...prevOrder,
      header_document: (prevOrder.header_document || []).filter((item) => item.id !== row.id),
    }));
  };


  const handleAnalyzeDocument = async (document_id) => {
    setIsChanged(true);
    try {
      const data = await analyzeOrder(document_id);
      setOrder((prevOrder) => {
        const updatedOrder = { ...prevOrder };
        Object.keys(data.body).forEach((key) => {
          if (key === 'bill_of_lading') {
            if (updatedOrder.bol === undefined || updatedOrder.bol === null || updatedOrder.bol === "") {
              updatedOrder.bol = data.body.bill_of_lading;
            }
          }
          else if (updatedOrder[key] === undefined || updatedOrder[key] === null || updatedOrder[key] === "") {
            updatedOrder[key] = data.body[key];
          }
        });
        return updatedOrder;
      });
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleCreatePdf = async () => {
    try {
      setLoadingPdf(true);
      const data = await createPdf(order);
      const pdfBase64 = data.body.pdf_base64;
      const byteCharacters = atob(pdfBase64);

      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);
      window.open(url);
    } catch (error) {
      setSnackbarOpen(true);
      setSnackbarMessage(error.response.data.detail);
      setSnackbarAlert('error');
    } finally {
      setLoadingPdf(false);
    }
  };

  const GridToolbar = () => {
    return (
      <GridToolbarContainer>
        <Button
          variant="contained"
          color="primary"
          size="small"
          sx={{ m: 0.25 }}
          startIcon={<AddIcon fontSize='small' />}
          onClick={() => handleCreateLineItem(order.id)}
        >
          Add Line Item
        </Button>
      </GridToolbarContainer>
    );
  };

  return (
    <Box
      sx={{
        width: '100%',
        overflow: 'auto',
      }}
    >
      <Typography variant="h6" component="span" display="flex" alignItems="center" sx={{ mb: 0 }}>
        <ViewInArIcon sx={{ mr: 1 }} />
        <b>{order.order_name}</b>
        <Box sx={{ flexGrow: 1 }} />
        <Button
          color="primary"
          variant="outlined"
          size='small'
          sx={{ mr: 1 }}
          onClick={handleCloseOrder}
        >
          Close
        </Button>
        <Button
          color="primary"
          variant="contained"
          size='small'
          sx={{ mr: 1 }}
          onClick={handleSaveOrder}
          startIcon={<SaveIcon />}
        >
          Save
        </Button>
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={!blobType ? 12 : 6}>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Typography variant="body1" component="span" display="flex" alignItems="center">
                <InfoIcon sx={{ mr: 0.5 }} fontSize='small' />
                <b>Status</b>
              </Typography>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  {order.is_sales_order ? (
                    <Typography variant="body1" component="div" display="flex" alignItems={'center'} mt={1.5}>
                      <b>{order.status}</b>
                    </Typography>
                  ) : (
                    <FormControl
                      fullWidth
                      size="small"
                      margin="dense"
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '10px',
                        },
                      }}
                    >
                      <InputLabel id="status-select-label">Status</InputLabel>
                      <Select
                        labelId="status-select-label"
                        value={order.status}
                        label="Status"
                        onChange={(e) => handleTextFieldChange(e, 'status')}
                      >
                        <MenuItem value="PO Sent">PO Sent</MenuItem>
                        <MenuItem value="In Transit">In Transit</MenuItem>
                        <MenuItem value="Delivered">Delivered</MenuItem>
                      </Select>
                    </FormControl>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <CustomTextField
                    label="Order Date"
                    disabled
                    value={order.order_date}
                    onChange={(e) => handleTextFieldChange(e, 'order_date')}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1" component="span" display="flex" alignItems="center">
                <LocalShippingIcon sx={{ mr: 0.5 }} fontSize='small' />
                <b>Shipping</b>
              </Typography>
              <Grid container spacing={1}>
                <Grid item xs={4}>
                  <CustomTextField
                    label="Address To"
                    value={order.ship_to_address}
                    onChange={(e) => handleTextFieldChange(e, 'ship_to_address')}
                    multiline
                  />
                </Grid>
                <Grid item xs={4}>
                  <CustomTextField
                    label="Tracking Number"
                    value={order.tracking_number}
                    onChange={(e) => handleTextFieldChange(e, 'tracking_number')}
                  />
                  <CustomTextField
                    label="Bill of Lading #"
                    value={order.bol}
                    onChange={(e) => handleTextFieldChange(e, 'bol')}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Grid container spacing={0} columnSpacing={1}>
                    <Grid item xs={12}>
                      <CustomTextField
                        label="Carrier"
                        value={order.carrier}
                        onChange={(e) => handleTextFieldChange(e, 'carrier')}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <CustomTextField
                        label="Pick Up Date"
                        value={order.pick_up_date}
                        onChange={(e) => handleTextFieldChange(e, 'pick_up_date')}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <CustomTextField
                        label="Delivery Date"
                        value={order.delivery_date}
                        onChange={(e) => handleTextFieldChange(e, 'delivery_date')}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1" component="span" display="flex" alignItems="center">
                <AttachMoneyIcon sx={{ mr: 0.5 }} fontSize='small' />
                <b>Invoice</b>
              </Typography>
              <Grid container spacing={1}>
                <Grid item xs={4}>
                  <CustomTextField
                    label="Subtotal ($)"
                    disabled
                    type="text"
                    value={
                      Array.isArray(order.header_detail)
                        ? order.header_detail.reduce((total, item) => {
                          const itemPrice = parseFloat(item?.item_price) || 0;
                          const orderQty = parseFloat(item?.order_qty) || 0;
                          return total + (itemPrice * orderQty);
                        }, 0).toLocaleString('en-US', { style: 'currency', currency: 'USD' })
                        : '$0.00'
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <CustomTextField
                    label="Freight ($)"
                    type="number"
                    value={order.total_freight}
                    onChange={(e) => handleTextFieldChange(e, 'total_freight')}
                  />
                </Grid>
                <Grid item xs={4}>
                  <CustomTextField
                    label="Total ($)"
                    disabled
                    type="text"
                    value={
                      (
                        (Array.isArray(order.header_detail)
                          ? order.header_detail.reduce((total, item) => {
                            const itemPrice = parseFloat(item?.item_price) || 0;
                            const orderQty = parseFloat(item?.order_qty) || 0;
                            return total + (itemPrice * orderQty);
                          }, 0)
                          : 0) +
                        (parseFloat(order?.total_freight) || 0)
                      ).toLocaleString('en-US', { style: 'currency', currency: 'USD' })
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1" component="span" display="flex" alignItems="center" width={'100%'} justifyContent={'space-between'}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <AttachFileIcon sx={{ mr: 0.5 }} fontSize='small' />
                  <b>Attach Documents </b>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  {loadingPdf ? (
                    <>
                      <CircularProgress size={16} />
                      <Typography variant="body2" component="span" display="flex" alignItems="center" sx={{ mr: 0.5 }}>Generating PDF...</Typography>
                    </>
                  ) : (
                    <Button
                      color="primary"
                      variant="text"
                      size='small'
                      startIcon={<PictureAsPdfIcon />}
                      onClick={handleCreatePdf}
                    >
                      Download PDF
                    </Button>
                  )}
                  <Button
                    color="primary"
                    variant="contained"
                    size='small'
                    startIcon={<ShareIcon />}
                    onClick={(event) => setAnchorEl(event.currentTarget)}
                  >
                    Share Documents
                  </Button>
                </Box>
              </Typography>
              <Typography
                variant="body1"
                component="span"
                display="flex"
                alignItems="center"
                flexWrap="wrap"
                gap={1}
              >
                <EmailPopover
                  anchorEl={anchorEl}
                  setAnchorEl={setAnchorEl}
                  documents={order?.header_document || []}
                />
                <FormControl
                  size="small"
                  sx={{
                    minWidth: 250,
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '10px',
                    },
                  }}
                >
                  <InputLabel id="doc-select-label">Link Document</InputLabel>
                  <Select
                    labelId="doc-select-label"
                    id="doc-select"
                    label="Link Document"
                    value={tempDoc || ''}
                    onChange={(e) => handleMapDocument(e.target.value)}
                  >
                    {documentOptions?.map((doc) => (
                      <MenuItem key={doc.id} value={doc}>
                        {doc.document_name} ({doc.category})
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {(order?.header_document || []).map((doc) => (
                  <React.Fragment key={doc.id}>
                    <Box>
                      <Typography
                        variant="body2"
                        component="span"
                        display="inline"
                        sx={{ m: 0, textDecoration: 'underline', cursor: 'pointer' }}
                        onClick={() => handleFetchDocument(doc)}
                      >
                        {doc.document_name}
                      </Typography>
                      {['Bill of Lading', 'Invoice'].includes(doc.category) &&
                        !doc.document_name.endsWith('.zip') && !doc.document_name.endsWith('.xlsx') && (
                          <>
                            <IconButton
                              sx={{ m: 0 }}
                              onClick={() => {
                                handleFetchDocument(doc);
                                handleAnalyzeDocument(doc.id);
                              }}
                            >
                              <AutoFixHighIcon color="info" fontSize='small' />
                            </IconButton>
                          </>
                        )}
                      <IconButton sx={{ m: 0 }} onClick={() => handleUnlinkDocument(doc)}>
                        <CloseIcon color="error" fontSize='small' />
                      </IconButton>
                    </Box>
                  </React.Fragment>
                ))}
              </Typography>
            </Grid>
          </Grid>
          <Divider sx={{ my: 1 }} />
          <Box sx={{ height: dataGridHeight, maxHeight: dataGridHeight, width: '100%', overflow: 'auto' }}>
            <DataGrid
              rows={rows}
              columns={columns}
              disableSelectionOnClick
              density="compact"
              disableRowSelectionOnClick
              hideFooter
              processRowUpdate={handleRowUpdate}
              slots={{
                toolbar: GridToolbar,
              }}
            />
          </Box>
        </Grid>
        {blobType && (
          <Grid item xs={6}>
            <Box
              display="flex"
              flexDirection="column"
              width="100%"
              height="75vh"
              maxHeight="75vh"
              overflow="hidden"
              mt={1}
            >
              {blobType === 'application/pdf' ? (
                <Box display="flex" flexDirection="column" width="100%" height="100%">
                  <PDFViewer pdfBlob={blobUrl} setBlobType={setBlobType} setBlobUrl={setBlobUrl} />
                </Box>
              ) : (
                <Box display="flex" flexDirection="column" width="100%" height="100%">
                  <Box display={'flex'} flexDirection={'row'} m={'0.25rem'}>
                    <Button
                      variant="contained"
                      size='small'
                      onClick={() => {
                        setBlobType('');
                        setBlobUrl('');
                      }}
                    >
                      Close Document
                    </Button>
                  </Box>
                  <img src={blobUrl} alt="docImg" />
                </Box>
              )}
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default OrderDetailViewer;
