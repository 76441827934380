import React, { useState, useEffect } from 'react';
import { Box, Typography, BottomNavigation, Paper, BottomNavigationAction } from '@mui/material';
import DataThresholdingIcon from '@mui/icons-material/DataThresholding';
import ViewListIcon from '@mui/icons-material/ViewList';
import trinity_logo from '../assets/logo.png';
import LogoutIcon from '@mui/icons-material/Logout';
import MobileDashboard from './MobileDashboard';
import MobileOrders from './MobileOrders';

const MobilePage = ({ signOut }) => {
  const [value, setValue] = useState(0);

  useEffect(() => {
    const setVh = () => {
      document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
    };

    setVh();
    window.addEventListener('resize', setVh);

    return () => {
      window.removeEventListener('resize', setVh);
    };
  }, []);

  const handleLogout = async () => {
    localStorage.removeItem('apiToken');
    await signOut();
  };


  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        bgcolor: '#ebebeb',
        height: 'calc(var(--vh, 1vh) * 100)',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
          justifyContent: 'space-between',
          bgcolor: 'grey.800',
          borderBottom: '1px solid #ebebeb',
          p: 2,
        }}
      >
        <img src={trinity_logo} alt="Trinity Logo" style={{ width: '110px' }} />
        <LogoutIcon fontSize='small' onClick={handleLogout} sx={{ color: 'white' }} />
      </Box>

      <Box
        sx={{
          flex: '1',
          display: 'flex',
          flexDirection: 'column',
          bgcolor: 'grey.800',
          overflow: 'hidden',
          pb: 7,
        }}
      >
        {value === 0 ? (
          <MobileDashboard />
        ) : (
          <MobileOrders />
        )}
      </Box>
      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={0}>
        <BottomNavigation
          showLabels
          sx={{ bgcolor: 'grey.200', borderTop: '1px solid #ccc', height: '3rem' }}
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
        >
          <BottomNavigationAction
            label={<Typography variant="caption" fontSize="0.8rem">Dashboard</Typography>}
            icon={<DataThresholdingIcon fontSize='small' />}
          />
          <BottomNavigationAction
            label={<Typography variant="caption" fontSize="0.8rem">Orders</Typography>}
            icon={<ViewListIcon />}
          />
        </BottomNavigation>
      </Paper>
    </Box>
  );
};

export default MobilePage;
